import { useState } from 'react';
import AchievementInfoModal from '../modals/AchievementInfoModal';
import Spinner from './Spinner';
import { ACHIEVEMENT_ICONS, ACHIEVEMENT_NAMES } from '../util/achievements';

function Achievement(props) {
    const [iconLoaded, setIconLoaded] = useState(false);
    const [showAchievementInfo, setShowAchievementInfo] = useState(false);

    const titleStyle = { color: props.unlocked ? 'var(--text-primary)' : 'var(--app-clr-4)' };

    function handleClick(e) {
        e.stopPropagation();
        
        setShowAchievementInfo(true);
    }

    return (
        <>
        <button className="achievement" onClick={handleClick}>
            <div className="achievement-title" style={titleStyle}>{props.title}</div>
            <div className="achievement-icon-container">
                <img 
                    src={props.unlocked ? ACHIEVEMENT_ICONS[props.id] : ACHIEVEMENT_ICONS.Locked} 
                    className={props.newAchievement ? "achievement-icon grow" : "achievement-icon"}
                    alt={ACHIEVEMENT_NAMES[props.id]}
                    style={{ display: iconLoaded ? 'block' : 'none' }} 
                    onLoad={() => setIconLoaded(true)} />
                <Spinner style={{ display: iconLoaded ? 'none' : 'flex' }} />
            </div>
        </button>
        <AchievementInfoModal 
            id={props.id}
            title={props.title}
            icon={props.unlocked ? ACHIEVEMENT_ICONS[props.id] : ACHIEVEMENT_ICONS.Locked}
            unlocked={props.unlocked}
            date={props.achievementInfo?.date}
            showAchievementInfo={showAchievementInfo}
            setShowAchievementInfo={setShowAchievementInfo} />
        </>
    );
}

export default Achievement;