import Guess from '../components/Guess';

function GuessesContainer(props) {
    return (
        <div className="guesses-container" role="list">
            { props.guesses.map((guess, i) => {
                return (<Guess 
                            key={i} 
                            eval={guess?.eval} 
                            dir={guess?.dir} 
                            areaEval={guess?.areaEval} 
                            countryName={guess?.name} 
                            animate={guess !== null}
                            descriptiveIcons={props.descriptiveIcons} />);
            }) }
        </div>
    );
}

export default GuessesContainer;