import TextInputContainer from './TextInputContainer';
import GuessesContainer from './GuessesContainer';

function UserInputContainer(props) {
    return (
        <div className="user-input-container">
            <TextInputContainer 
                handleNewGuess={props.handleNewGuess} 
                disabled={props.shouldDisableGuessing}
                addAchievementFromEvent={props.addAchievementFromEvent} />
            <GuessesContainer 
                targetCountryIso={props.targetCountryIso} 
                guesses={props.guesses}
                descriptiveIcons={props.descriptiveIcons} />
        </div>
    );
}

export default UserInputContainer;