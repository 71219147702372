import { 
    EVALS, 
    EVAL_SYMBOLS_CLASSES,
    EVAL_SYMBOLS_COMPONENT,
    EVAL_SYMBOLS_DESCRIPTIVE, 
    DIRECTION_SYMBOLS,
    AREA_EVAL_SYMBOLS } from '../util/evals';
import BlankCircle from '../icons/blank_circle.svg';
// import { ReactComponent as BlankCircle } from '../icons/blank_circle.svg';
import { cloneElement } from 'react';

const descriptiveEvals = {
    [EVALS.Correct]: 'Correct country',
    [EVALS.Border]: 'Bordering country',
    [EVALS.Continent]: 'Correct continent',
    [EVALS.Incorrect]: 'Incorrect country'
}

function Guess(props) {
    return (
        <div className="guess" tabIndex={0} role="listitem">
            { props.animate &&
                <>
                <div className="staggered-circles">
                    {/* <div className="circle">
                        <BlankCircle />
                    </div>
                    <div className="circle">
                        <BlankCircle />
                    </div>
                    <div className="circle">
                        <BlankCircle />
                    </div> */}
                    <div className="circle" />
                    <div className="circle" />
                    <div className={`circle ${EVAL_SYMBOLS_CLASSES[props.eval]}`} />
                    {/* <img className="circle" src={BlankCircle} alt="" />
                    <img className="circle" src={BlankCircle} alt="" />
                    <img className="circle" src={EVAL_SYMBOLS[props.eval]} alt="" /> */}
                </div>
                <div className="fill-bar">
                    <div className="guess-text">
                        <div className="guess-text-inner">
                            <div className="guess-text-country-container">
                                    { cloneElement(
                                        EVAL_SYMBOLS_COMPONENT[props.eval],
                                        {title: descriptiveEvals[props.eval]}
                                    ) }
                                {/* <img src={EVAL_SYMBOLS[props.eval]} alt={descriptiveEvals[props.eval]} title={descriptiveEvals[props.eval]} /> */}
                                <span className="guess-text-country">{props.countryName}</span> 
                            </div>
                            { props.animate &&
                                <span className="guess-text-hints" role="img" aria-label={props.eval === EVALS.Correct ? 'You found the target country!' : `The target country is ${props.dir} with a ${props.areaEval}`}>
                                    { props.descriptiveIcons &&
                                        cloneElement(
                                            EVAL_SYMBOLS_DESCRIPTIVE[props.eval],
                                            {
                                                className: "guess-descriptive-icon",
                                                title: descriptiveEvals[props.eval],
                                                'aria-hidden': true
                                            }
                                        ) }
                                        {/* <img className="guess-descriptive-icon" src={EVAL_SYMBOLS_DESCRIPTIVE[props.eval]} alt="" aria-hidden="true" title={descriptiveEvals[props.eval]} /> } */}
                                    { props.eval !== EVALS.Correct &&
                                        <>
                                        { cloneElement(
                                            DIRECTION_SYMBOLS[props.dir],
                                            {title: `The target country is ${props.dir.toLowerCase()}`,
                                             'aria-hidden': true}
                                        ) }
                                        { cloneElement(
                                            AREA_EVAL_SYMBOLS[props.areaEval],
                                            {title: `The target country has a ${props.areaEval.toLowerCase()}`,
                                             'aria-hidden': true}
                                        ) }
                                        
                                        {/* <img src={DIRECTION_SYMBOLS[props.dir]} alt="" aria-hidden="true" title={`The target country is ${props.dir.toLowerCase()}`} />
                                        <img src={AREA_EVAL_SYMBOLS[props.areaEval]} alt="" aria-hidden="true" title={`The target country has a ${props.areaEval.toLowerCase()}`} /> */}
                                        </> }
                                </span> }
                        </div>
                    </div>
                </div>
                </> }
        </div>
    );
}

export default Guess;