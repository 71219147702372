import { useMemo } from 'react';
import { DateTime } from 'luxon';

import Modal from './Modal';
import StatsDataPoint from '../components/StatsDataPoint';
import DistributionBar from '../components/DistributionBar';
import CopyButton from '../components/CopyButton';
import { CONTINENTS, EVALS } from '../util/evals';

function StatisticsModal(props) {
    const modalGuess = useMemo(() => {
        return Math.max(...Object.values(props.stats.distribution));
    }, [JSON.stringify(props.stats.distribution)]);

    const winPercentage = useMemo(() => {
        let percentage;

        if (props.stats.completed === 0) {
            percentage = 0;
        } else {
            percentage = Math.round((props.stats.won / props.stats.completed) * 100);
            
            if (percentage > 100)
                percentage = 100;
        }
        
        return percentage;
    }, [props.stats.completed, props.stats.won]);

    const bestContinent = useMemo(() => {
        let best;
        const continents = Object.keys(props.stats.continentCount);
        const counts = Object.values(props.stats.continentCount);

        if (counts.every(count => count === 0)) {
            best = 'No Data';
        } else {
            best = CONTINENTS[continents.reduce((prev, curr) => {
                return props.stats.continentCount[prev] < props.stats.continentCount[curr] ? curr : prev;
            })];
        }

        return best;
    }, [JSON.stringify(props.stats.continentCount)]);
    
    function getGameResults(dateString) {
        const startDate = DateTime.fromFormat(props.startDateString, 'dd/MM/yyyy');
        const presentDate = DateTime.fromFormat(dateString, 'dd/MM/yyyy');

        const gameNumber = presentDate.diff(startDate, 'days').toObject().days + 1;
        let results = `#Anywheredle #${gameNumber}`;

        const guesses = JSON.parse(localStorage.getItem("guesses"))[dateString];
        if (guesses) {
            let score;

            if (!guesses.some(guess => guess.eval === EVALS.Correct)) {
                score = '-';
            } else {
                score = guesses.indexOf(null);
                
                if (score === -1)
                    score = 6;
            }

            results += `\n${score}/6\n`;

            const history = guesses.reduce((arr, guess) => {
                if (guess) {
                    if (guess.eval === EVALS.Incorrect) {
                        arr.push('🔴');
                    } else if (guess.eval === EVALS.Continent) {
                        arr.push('🟡');
                    } else if (guess.eval === EVALS.Border) {
                        arr.push('🔵');
                    } else {
                        arr.push('🟢');
                    }
                }
                return arr;
            }, []).join('\n');

            results += history + '\nanywheredle.com';
        }
        
        return results;
    }

    return (
        <Modal 
            showModal={props.showModal}
            rootClass="stats-modal-root"
            modalOverlayClass="modal-overlay small-modal-overlay"
            modalClass="small-modal"
            modalHeaderClass="modal-header"
            modalTitleClass="modal-title"
            modalContentClass="stats-modal-content"
            title="STATISTICS" 
            setShowModal={props.setShowStats}
            multiClose={true}
        >
            <section>
                <div className="stats-modal-data">
                    <StatsDataPoint value={props.stats.completed} label="Completed" />
                    <StatsDataPoint value={props.stats.won} label="Won" />
                    <StatsDataPoint value={winPercentage} label="Win %" />
                    <StatsDataPoint value={props.stats.currStreak} label="Current Streak" />
                    <StatsDataPoint value={props.stats.bestStreak} label="Best Streak" />
                    <StatsDataPoint value={bestContinent} label="Best Continent" />
                </div>
            </section>
            <div className="modal-separator"></div>
            <section style={{ width: '100%' }}>
                <div className="stats-modal-subheading">Performance Distribution</div>
                <div className="performance-distribution-container">
                    { Object.keys(props.stats.distribution).map(guessNumber => {
                        return (
                            <DistributionBar
                                key={guessNumber}
                                value={props.stats.distribution[guessNumber]} 
                                label={guessNumber} 
                                modalGuess={modalGuess} /> 
                        );
                    }) }
                </div>
            </section>
            <div className="modal-separator"></div>
            { ( (props.stats.lastCompleted === props.dateString && 
                    JSON.parse(localStorage.getItem("guesses"))[props.dateString]?.indexOf(null) !== 0) || 
                (props.stats.lastCompleted === props.previousDayDateString && 
                    JSON.parse(localStorage.getItem("guesses"))[props.previousDayDateString]?.indexOf(null) !== 0) ) &&
                <div className="share-button-container">
                    <CopyButton 
                        className={`share-button ${props.stats.lastCompleted === props.previousDayDateString && 'share-button-faded'}`}
                        content={() => getGameResults(props.stats.lastCompleted)}
                        notification="Copied Results"
                    >
                        { props.stats.lastCompleted === props.dateString ? "Share Today's Score" : "Share Previous Score" }
                    </CopyButton>
                </div> }
        </Modal>
    );
}

export default StatisticsModal;