function Spinner(props) {
    return (
        <div 
            className="spinner-container" 
            style={props.style}>
            <div className="spinner" />
        </div>
    )
}

export default Spinner;