import Correct from '../icons/correct.svg';
import Border from '../icons/border.svg';
import Continent from '../icons/continent.svg';
import Incorrect from '../icons/incorrect.svg';

import { ReactComponent as CorrectComponent } from '../icons/correct.svg';
import { ReactComponent as BorderComponent } from '../icons/border.svg';
import { ReactComponent as ContinentComponent } from '../icons/continent.svg';
import { ReactComponent as IncorrectComponent } from '../icons/incorrect.svg';
import { ReactComponent as CorrectDescriptive } from '../icons/correct_descriptive.svg';
import { ReactComponent as BorderDescriptive } from '../icons/border_descriptive.svg';
import { ReactComponent as ContinentDescriptive } from '../icons/continent_descriptive.svg';
import { ReactComponent as IncorrectDescriptive } from '../icons/incorrect_descriptive.svg';

import { ReactComponent as North } from '../icons/north.svg';
import { ReactComponent as South } from '../icons/south.svg';
import { ReactComponent as East } from '../icons/east.svg';
import { ReactComponent as West } from '../icons/west.svg';
import { ReactComponent as NorthEast } from '../icons/north_east.svg';
import { ReactComponent as NorthWest } from '../icons/north_west.svg';
import { ReactComponent as SouthEast } from '../icons/south_east.svg';
import { ReactComponent as SouthWest } from '../icons/south_west.svg';

import { ReactComponent as Smaller } from '../icons/smaller.svg';
import { ReactComponent as Larger } from '../icons/larger.svg';

export const EVALS = {
    Correct: 'Correct',
    Border: 'Border',
    Continent: 'Continent',
    Incorrect: 'Incorrect'
};

export const EVAL_SYMBOLS_CLASSES = {
    [EVALS.Correct]: "circle--correct",
    [EVALS.Border]: "circle--border",
    [EVALS.Continent]: "circle--continent",
    [EVALS.Incorrect]: "circle--incorrect"
}

export const EVAL_SYMBOLS = {
    [EVALS.Correct]: Correct,
    [EVALS.Border]: Border,
    [EVALS.Continent]: Continent,
    [EVALS.Incorrect]: Incorrect
}

export const EVAL_SYMBOLS_COMPONENT = {
    [EVALS.Correct]: <CorrectComponent />,
    [EVALS.Border]: <BorderComponent />,
    [EVALS.Continent]: <ContinentComponent />,
    [EVALS.Incorrect]: <IncorrectComponent />
}

export const EVAL_SYMBOLS_DESCRIPTIVE = {
    [EVALS.Correct]: <CorrectDescriptive />,
    [EVALS.Border]: <BorderDescriptive />,
    [EVALS.Continent]: <ContinentDescriptive />,
    [EVALS.Incorrect]: <IncorrectDescriptive />
}

export const DIRECTIONS = {
    N: 'North',
    S: 'South',
    E: 'East',
    W: 'West',
    NE: 'North-East',
    NW: 'North-West',
    SE: 'South-East',
    SW: 'South-West'
};

export const DIRECTION_SYMBOLS = {
    [DIRECTIONS.N]: <North />,
    [DIRECTIONS.S]: <South />,
    [DIRECTIONS.E]: <East />,
    [DIRECTIONS.W]: <West />,
    [DIRECTIONS.NE]: <NorthEast />,
    [DIRECTIONS.NW]: <NorthWest />,
    [DIRECTIONS.SE]: <SouthEast />,
    [DIRECTIONS.SW]: <SouthWest />
};

export const AREA_EVALS = {
    Smaller: 'Smaller Surface Area',
    Larger: 'Larger Surface Area'
};

export const AREA_EVAL_SYMBOLS = {
    [AREA_EVALS.Smaller]: <Smaller />,
    [AREA_EVALS.Larger]: <Larger />
};

export const CONTINENTS = {
    africa: 'Africa',
    antarctica: 'Antarctica',
    asia: 'Asia',
    europe: 'Europe',
    namerica: 'North America',
    oceania: 'Oceania',
    samerica: 'South America'
}

export function getGuessEval(country, targetCountry) {
    let guessEval;

    if (isCorrectGuess(country, targetCountry)) guessEval = EVALS.Correct;
    else if (isBorderGuess(country, targetCountry)) guessEval = EVALS.Border;
    else if (isContinentGuess(country, targetCountry)) guessEval = EVALS.Continent;
    else guessEval = EVALS.Incorrect;

    return guessEval;
}

function isCorrectGuess(country, targetCountry) {
    return country.iso === targetCountry.iso;
}

function isBorderGuess(country, targetCountry) {
    return country.borders?.includes(targetCountry.iso);
}

function isContinentGuess(country, targetCountry) {
    return country.continent === targetCountry.continent;
}

export function getGuessDirection(country, targetCountry) {
    const [latStart, longStart] = [country.lat * Math.PI / 180, country.long * Math.PI / 180];
    const [latEnd, longEnd] = [targetCountry.lat * Math.PI / 180, targetCountry.long * Math.PI / 180];
    let deltaLong = (longEnd - longStart);
    const projectedDeltaLat = Math.log(Math.tan(Math.PI / 4 + latEnd / 2) / Math.tan(Math.PI / 4 + latStart / 2)); 

    if (Math.PI < Math.abs(deltaLong))
        deltaLong = deltaLong > 0 ? -(2 * Math.PI - deltaLong) : 2 * Math.PI + deltaLong;
        
    const loxodromeBearing = (Math.atan2(deltaLong, projectedDeltaLat) * 180 / Math.PI + 360) % 360;
    let direction;

    if (loxodromeBearing >= 337.5 || loxodromeBearing <= 22.5) {
        direction = DIRECTIONS.N;
    } else if (loxodromeBearing < 67.5) {
        direction = DIRECTIONS.NE;
    } else if (loxodromeBearing <= 112.5) {
        direction = DIRECTIONS.E;
    } else if (loxodromeBearing < 157.5) {
        direction = DIRECTIONS.SE;
    } else if (loxodromeBearing <= 202.5) {
        direction = DIRECTIONS.S;
    } else if (loxodromeBearing < 247.5) {
        direction = DIRECTIONS.SW;
    } else if (loxodromeBearing <= 292.5) {
        direction = DIRECTIONS.W;
    } else {
        direction = DIRECTIONS.NW;
    }
    
    return direction;
}