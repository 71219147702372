import { useEffect, useMemo, useState } from 'react';
import SymbolContainer from '../containers/SymbolContainer';
import SymbolModal from '../modals/SymbolModal';
import { ACHIEVEMENT_IDS } from '../util/achievements';

function Symbol(props) {
    const [backStyle, setBackStyle] = useState(null);
    const frontStyle = useMemo(() => { 
        return { backgroundImage: props.challengeMode ? 
                                    `url(${props.missingIcon})` : `url(${props.icon})`, backgroundSize: '50%' };
    }, [props.challengeMode, props.missingIcon, props.icon]);
    const [noSymbolMessage, setNoSymbolMessage] = useState(null);
    const [showSymbolModal, setShowSymbolModal] = useState(false);

    useEffect(() => {
        async function fetchSymbol() {
            let backgroundImage, backgroundSize = null, noSymbolMessage = null;
            
            if (props.iso === 'gb-eng' || 
                props.iso === 'gb-sct' || 
                props.iso === 'gb-wls' || 
                props.iso === 'gb-nir') {
                    let iso = props.iso;
                    
                    if (props.type === 'coa')
                        iso = props.iso === 'gb-sct' ? 'gb-sct' : 'gb';

                    backgroundImage = `url(${process.env.PUBLIC_URL}/${props.type}/${iso}.svg)`;
            } else {
                try {
                    const response = await fetch(`https://restcountries.com/v3.1/alpha/${props.iso}`);
                    const data = await response.json();

                    const symbol = data[0]?.[props.resourceAttribute]?.svg;

                    if (!symbol) {
                        backgroundImage = `url(${props.missingIcon})`;
                        backgroundSize = '50%';
                        noSymbolMessage = 'Not Found';
                    } else {
                        backgroundImage = `url(${symbol})`;
                    }
                } catch(err) {
                    console.log(err);
                }
            }
            
            return { backgroundImage, backgroundSize, noSymbolMessage };
        }
        
        fetchSymbol().then(symbol => {
            setBackStyle({ backgroundImage: symbol.backgroundImage, backgroundSize: symbol.backgroundSize });
            setNoSymbolMessage(symbol.noSymbolMessage);
        });
    }, [props.iso, props.type, props.resourceAttribute, props.missingIcon]);

    return (
        <>
        <SymbolContainer type={props.type} reveal={props.reveal} ariaLabel={props.type}>
            <div 
                className={`${props.type}-front${(props.darkTheme && props.challengeMode) ? ' symbol-front-dark' : ''}`} 
                style={frontStyle} />
            <div 
                className={`${props.type}-back`}
                style={backStyle}
                onClick={() => {
                    setShowSymbolModal(true);
                    props.addAchievementFromEvent({ 
                        id: ACHIEVEMENT_IDS.Scholar,
                        delay: props.gameEnd ? false : true
                    });
                }} />
        </SymbolContainer>
        <SymbolModal 
            showModal={showSymbolModal} 
            title={props.modalTitle}
            subtitle={noSymbolMessage}
            modalContentStyle={backStyle} 
            setShowSymbolModal={setShowSymbolModal} />
        </>
    );
}

export default Symbol;