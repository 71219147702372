import { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import FocusTrap from 'focus-trap-react';
import { ReactComponent as Exit } from '../icons/exit.svg';

function Modal({ setShowModal, ...props }) {
    const overlayRef = useRef(null);
    const smallOverlayRootRef = useRef(null);
    const modalContent = (<FocusTrap focusTrapOptions={{ initialFocus: false, allowOutsideClick: true }}>
                            <div ref={overlayRef} className={props.modalOverlayClass} onClick={props.multiClose ? handleCloseModal : undefined}>
                                <div className={props.modalClass}>
                                    <header className={props.modalHeaderClass}>
                                        <div className={props.modalTitleClass}>{props.title}</div>
                                        <button className="modal-exit-button" onClick={handleCloseModal} aria-label="close modal">
                                            <span role="img"><Exit className="modal-exit-icon" /></span>
                                        </button>
                                    </header>
                                    <div className={props.modalContentClass} style={props.modalContentStyle}>
                                        { props.subtitle && <p className="symbol-subtitle"><b>{props.subtitle}</b></p> }
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                          </FocusTrap>);

    function handleCloseModal(e) {
        e.stopPropagation(); 

        setShowModal(false);
    }

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.key === 'Escape') {
                setShowModal(false);
            }
        }

        document.addEventListener("keydown", handleKeyDown);

        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [setShowModal]);

    return (
        <CSSTransition
            nodeRef={overlayRef}
            in={props.showModal}
            timeout={250}
            classNames="modal-transition"
            appear
            unmountOnExit
        >  
            { props.rootClass ?
                <CSSTransition
                    nodeRef={smallOverlayRootRef}
                    in={props.showModal}
                    timeout={250}
                    classNames="small-modal-overlay-root-transition"
                    appear
                    unmountOnExit
                >  
                    <div ref={smallOverlayRootRef} className={`small-modal-overlay-root ${props.rootClass}`}>{modalContent}</div>
                </CSSTransition> : modalContent }
        </CSSTransition>   
    );
}

export default Modal;