import { useEffect, useRef, useState } from 'react';

import BorderCell from '../components/BorderCell';
import Symbol from '../components/Symbol';
import COAIcon from '../icons/coa.svg';
import noCOAIcon from '../icons/no_coa.svg';
import flagIcon from '../icons/flag.svg';
import noFlagIcon from '../icons/no_flag.svg';

const cellOrder = [1, 5, 3, 6, 2, 4];
const cellBgPos = [
    'right bottom', 
    'center bottom',
    'left bottom',
    'right top',
    'center top',
    'left top'
];

function HintsContainer(props) {
    const [borderFrontStyle, setBorderFrontStyle] = useState(null);
    const [borderBackStyle, setBorderBackStyle] = useState(null);

    useEffect(() => {
        if (props.cellsRevealed.indexOf(false) === -1) {
            setBorderFrontStyle({ opacity: 0 });
            setBorderBackStyle({
                backgroundImage: `url(${process.env.PUBLIC_URL}/borders/${props.targetCountry.uuid}.svg)`,
                opacity: 1
            });
        }

    }, [JSON.stringify(props.cellsRevealed), props.targetCountry.uuid]);

    return (
        <div className="hints-container">
            <Symbol
                darkTheme={props.darkTheme}
                type="coa"
                modalTitle="National Emblem"
                resourceAttribute="coatOfArms"
                icon={COAIcon}
                missingIcon={noCOAIcon}
                iso={props.targetCountry.iso} 
                reveal={props.revealCOA} 
                challengeMode={props.challengeMode}
                gameEnd={props.gameEnd}
                addAchievementFromEvent={props.addAchievementFromEvent} />
            <div className="border-container" onClick={props.boundaryClicked} style={{ cursor: props.gameEnd && 'pointer' }}>
                <div className="border-container-front" style={borderFrontStyle}>
                    { cellOrder.map((order, i) => {
                        return (<BorderCell 
                                    key={order} 
                                    targetCountry={props.targetCountry} 
                                    reveal={props.cellsRevealed[order - 1]} 
                                    order={order} 
                                    bgPos={cellBgPos[i]} />);
                    }) }
                </div>
                <div className="border-container-back" style={borderBackStyle} /> 
            </div>
            <Symbol
                darkTheme={props.darkTheme}
                type="flag"
                modalTitle="National Flag"
                resourceAttribute="flags"
                icon={flagIcon}
                missingIcon={noFlagIcon}
                iso={props.targetCountry.iso}
                reveal={props.revealFlag} 
                challengeMode={props.challengeMode}
                gameEnd={props.gameEnd}
                addAchievementFromEvent={props.addAchievementFromEvent} />
        </div>
    );
}

export default HintsContainer;