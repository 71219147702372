const COUNTRY_DATA = {
    af: {
        name: "Afghanistan",
        iso: "af",
        alt: "",
        continent: "asia",
        lat: 33.93911,
        long: 67.709953,
        uuid: "cufEsGu4U4y7TzP2qu6jBo",
        area: 652230,
        borders: [
            "ir",
            "pk",
            "tm",
            "uz",
            "tj",
            "cn"
        ]
    },

    al: {
        name: "Albania",
        iso: "al",
        alt: "",
        continent: "europe",
        lat: 41.153332,
        long: 20.168331,
        uuid: "3TW9bouEkj4eets5u8Rrov",
        area: 28748,
        borders: [
            "me",
            "gr",
            "mk",
            "xk"
        ]
    },

    dz: {
        name: "Algeria",
        iso: "dz",
        alt: "",
        continent: "africa",
        lat: 28.033886,
        long: 1.659626,
        uuid: "3BHYNW6am4wfcPa98jV2e2",
        area: 2381741,
        borders: [
            "tn",
            "ly",
            "ne",
            "eh",
            "mr",
            "ml",
            "ma"
        ]
    },

    as: {
        name: "American Samoa",
        iso: "as",
        alt: "AmericanSamoa",
        continent: "oceania",
        lat: -14.270972,
        long: -170.132217,
        uuid: "3M2TdVD5XYX2E25XSBGQGZ",
        area: 199
    },

    ad: {
        name: "Andorra",
        iso: "ad",
        alt: "",
        continent: "europe",
        lat: 42.546245,
        long: 1.601554,
        uuid: "6nL8KbKvzWckTpN74xZcUV",
        area: 468,
        borders: [
            "fr",
            "es"
        ]
    },

    ao: {
        name: "Angola",
        iso: "ao",
        alt: "",
        continent: "africa",
        lat: -11.202692,
        long: 17.873887,
        uuid: "xwSsiRVNkkCS8Qt9quUwEL",
        area: 1246700,
        borders: [
            "cg",
            "cd",
            "zm",
            "na"
        ]
    },

    ai: {
        name: "Anguilla",
        iso: "ai",
        alt: "",
        continent: "namerica",
        lat: 18.220554,
        long: -63.068615,
        uuid: "uU94XvJbuErJNNm3quDSx8",
        area: 91
    },

    aq: {
        name: "Antarctica",
        iso: "aq",
        alt: "",
        continent: "antarctica",
        lat: -75.250973,
        long: -0.071389,
        uuid: "78rUSDDe7mhYEi8VEiqRDb",
        area: 14000000
    },

    ag: {
        name: "Antigua and Barbuda",
        iso: "ag",
        alt: "AntiguaandBarbuda",
        continent: "namerica",
        lat: 17.060816,
        long: -61.796428,
        uuid: "ywDMRL2ad83dcrdu6VKeYj",
        area: 442
    },

    ar: {
        name: "Argentina",
        iso: "ar",
        alt: "",
        continent: "samerica",
        lat: -38.416097,
        long: -63.616672,
        uuid: "pzxBGwhAZkbGFRAt84HDGG",
        area: 2780400,
        borders: [
            "bo",
            "br",
            "cl",
            "py",
            "uy"
        ]
    },

    am: {
        name: "Armenia",
        iso: "am",
        alt: "",
        continent: "asia",
        lat: 40.069099,
        long: 45.038189,
        uuid: "3zeEWHtaztwvHRHDTXaP9Y",
        area: 29743,
        borders: [
            "az",
            "ge",
            "ir",
            "tr"
        ]
    },

    aw: {
        name: "Aruba",
        iso: "aw",
        alt: "",
        continent: "namerica",
        lat: 12.52111,
        long: -69.968338,
        uuid: "3wM3pBZb4YaUhEfQjjkAJE",
        area: 180
    },

    au: {
        name: "Australia",
        iso: "au",
        alt: "",
        continent: "oceania",
        lat: -25.274398,
        long: 133.775136,
        uuid: "3GNrodakhYpnJmzXubHZVh",
        area: 7692024
    },

    at: {
        name: "Austria",
        iso: "at",
        alt: "",
        continent: "europe",
        lat: 47.516231,
        long: 14.550072,
        uuid: "3JdbYKgmxW8o6QqBf9hH75",
        area: 83871,
        borders: [
            "cz",
            "de",
            "hu",
            "it",
            "li",
            "sk",
            "si",
            "ch"
        ]
    },

    az: {
        name: "Azerbaijan",
        iso: "az",
        alt: "",
        continent: "asia",
        lat: 40.143105,
        long: 47.576927,
        uuid: "3Lfr4JCpfoLHzx9NcAwADL",
        area: 86600,
        borders: [
            "am",
            "ge",
            "ir",
            "ru",
            "tr"
        ]
    },

    bs: {
        name: "Bahamas",
        iso: "bs",
        alt: "",
        continent: "namerica",
        lat: 25.03428,
        long: -77.39628,
        uuid: "fhHXTXJnsv8ZLiKwo9m4pq",
        area: 13943
    },

    bh: {
        name: "Bahrain",
        iso: "bh",
        alt: "",
        continent: "asia",
        lat: 25.930414,
        long: 50.637772,
        uuid: "xb8XpMx8MVBYBzPrWJvs2S",
        area: 765
    },

    bd: {
        name: "Bangladesh",
        iso: "bd",
        alt: "",
        continent: "asia",
        lat: 23.684994,
        long: 90.356331,
        uuid: "vnHQi2Ljn4JqCEHWcgJkoE",
        area: 147570,
        borders: [
            "mm",
            "in"
        ]
    },

    bb: {
        name: "Barbados",
        iso: "bb",
        alt: "",
        continent: "namerica",
        lat: 13.193887,
        long: -59.543198,
        uuid: "37ASmybdycak3A8dPHJuCb",
        area: 430
    },

    by: {
        name: "Belarus",
        iso: "by",
        alt: "",
        continent: "europe",
        lat: 53.709807,
        long: 27.953389,
        uuid: "D6LDRPiG9EKST4sDbn6pYX",
        area: 207600,
        borders: [
            "lv",
            "lt",
            "pl",
            "ru",
            "ua"
        ]
    },

    be: {
        name: "Belgium",
        iso: "be",
        alt: "",
        continent: "europe",
        lat: 50.503887,
        long: 4.469936,
        uuid: "3eSrCZwyzvyZcmmA3PJWX7",
        area: 30528,
        borders: [
            "fr",
            "de",
            "lu",
            "nl"
        ]
    },

    bz: {
        name: "Belize",
        iso: "bz",
        alt: "",
        continent: "namerica",
        lat: 17.189877,
        long: -88.49765,
        uuid: "5nKf97g2MWuVuJyJtxMQLs",
        area: 22966,
        borders: [
            "gt",
            "mx"
        ]
    },

    bj: {
        name: "Benin",
        iso: "bj",
        alt: "",
        continent: "africa",
        lat: 9.30769,
        long: 2.315834,
        uuid: "3BLHeLw9Q7FiHo5YBfQfMn",
        area: 112622,
        borders: [
            "bf",
            "ne",
            "ng",
            "tg"
        ]
    },

    bm: {
        name: "Bermuda",
        iso: "bm",
        alt: "",
        continent: "namerica",
        lat: 32.321384,
        long: -64.75737,
        uuid: "HYmqTbqkF7GmPZiRDgLAqs",
        area: 54
    },

    bt: {
        name: "Bhutan",
        iso: "bt",
        alt: "",
        continent: "asia",
        lat: 27.514162,
        long: 90.433601,
        uuid: "35gQt3XTapgDbUg8KTtKe4",
        area: 38394,
        borders: [
            "cn",
            "in"
        ]
    },

    bo: {
        name: "Bolivia",
        iso: "bo",
        alt: "",
        continent: "samerica",
        lat: -16.290154,
        long: -63.588653,
        uuid: "3Q2f8oFt2YDuJ3rkvkX7iP",
        area: 1098581,
        borders: [
            "ar",
            "br",
            "cl",
            "py",
            "pe"
        ]
    },

    ba: {
        name: "Bosnia and Herzegovina",
        iso: "ba",
        alt: "BosniaandHerzegovina",
        continent: "europe",
        lat: 43.915886,
        long: 17.679076,
        uuid: "yU6CNgTjeQQNR6VUAKUJRU",
        area: 51209,
        borders: [
            "hr",
            "me",
            "rs"
        ]
    },

    bw: {
        name: "Botswana",
        iso: "bw",
        alt: "",
        continent: "africa",
        lat: -22.328474,
        long: 24.684866,
        uuid: "u6HL672nzEW9QPFGMr2ma7",
        area: 582000,
        borders: [
            "na",
            "za",
            "zm",
            "zw"
        ]
    },

    bv: {
        name: "Bouvet Island",
        iso: "bv",
        alt: "BouvetIsland",
        continent: "antarctica",
        lat: -54.423199,
        long: 3.413194,
        uuid: "rAiPWQaC2yMAAZNk9QHdSm",
        area: 49
    },

    br: {
        name: "Brazil",
        iso: "br",
        alt: "",
        continent: "samerica",
        lat: -14.235004,
        long: -51.92528,
        uuid: "eC6i37rJJY7EjFfSapJPXY",
        area: 8515767,
        borders: [
            "ar",
            "bo",
            "co",
            "gf",
            "gy",
            "py",
            "pe",
            "sr",
            "uy",
            "ve"
        ]
    },

    io: {
        name: "British Indian Ocean Territory",
        iso: "io",
        alt: "BritishIndianOceanTerritory",
        continent: "asia",
        lat: -6.343194,
        long: 71.876519,
        uuid: "3U8UMgCg565Q3zAE35xsiG",
        area: 60
    },

    vg: {
        name: "British Virgin Islands",
        iso: "vg",
        alt: "BritishVirginIslands",
        continent: "namerica",
        lat: 18.420695,
        long: -64.639968,
        uuid: "3Cyh7Xupp5pfcXSH8KtTNd",
        area: 151
    },

    bn: {
        name: "Brunei",
        iso: "bn",
        alt: "",
        continent: "asia",
        lat: 4.535277,
        long: 114.727669,
        uuid: "qAwFQG67znnsEiUxDd8z4k",
        area: 5765,
        borders: [
            "my"
        ]
    },

    bg: {
        name: "Bulgaria",
        iso: "bg",
        alt: "",
        continent: "europe",
        lat: 42.733883,
        long: 25.48583,
        uuid: "3ACJyXBvUpakixjtvxoUXx",
        area: 110879,
        borders: [
            "gr",
            "mk",
            "ro",
            "rs",
            "tr"
        ]
    },

    bf: {
        name: "Burkina Faso",
        iso: "bf",
        alt: "BurkinaFaso",
        continent: "africa",
        lat: 12.238333,
        long: -1.561593,
        uuid: "HpS26h57neKYT8Yn5wbs6R",
        area: 272967,
        borders: [
            "bj",
            "ci",
            "gh",
            "ml",
            "ne",
            "tg"
        ]
    },

    bi: {
        name: "Burundi",
        iso: "bi",
        alt: "",
        continent: "africa",
        lat: -3.373056,
        long: 29.918886,
        uuid: "PwMTcRoqhpmHbVJ3J2YGoZ",
        area: 27834,
        borders: [
            "cd",
            "rw",
            "tz"
        ]
    },

    kh: {
        name: "Cambodia",
        iso: "kh",
        alt: "",
        continent: "asia",
        lat: 12.565679,
        long: 104.990963,
        uuid: "zpiNUi2YbVNQB73sbVspCJ",
        area: 181035,
        borders: [
            "la",
            "th",
            "vn"
        ]
    },

    cm: {
        name: "Cameroon",
        iso: "cm",
        alt: "",
        continent: "africa",
        lat: 7.369722,
        long: 12.354722,
        uuid: "mUoTeJo2k63JjN7xhA4xqV",
        area: 475442,
        borders: [
            "cf",
            "td",
            "cg",
            "gq",
            "ga",
            "ng"
        ]
    },

    ca: {
        name: "Canada",
        iso: "ca",
        alt: "",
        continent: "namerica",
        lat: 56.130366,
        long: -106.346771,
        uuid: "3tR5CAbYnRMiaLpKtfRq5A",
        area: 9984670,
        borders: [
            "us"
        ]
    },

    cv: {
        name: "Cape Verde",
        iso: "cv",
        alt: "CapeVerde",
        continent: "africa",
        lat: 16.002082,
        long: -24.013197,
        uuid: "3zpDvDz387NVLGWSVKesgS",
        area: 4033
    },

    bq: {
        name: "Caribbean Netherlands",
        iso: "bq",
        alt: "CaribbeanNetherlands",
        continent: "namerica",
        lat: 12.1784,
        long: -68.2385,
        uuid: "UWqmXwBHT4VpFGNfajA7DQ",
        area: 328
    },

    ky: {
        name: "Cayman Islands",
        iso: "ky",
        alt: "CaymanIslands",
        continent: "namerica",
        lat: 19.513469,
        long: -80.566956,
        uuid: "DMLxDH92e3EwT7nqBhbA4j",
        area: 264
    },

    cf: {
        name: "Central African Republic",
        iso: "cf",
        alt: "CentralAfricanRepublic",
        continent: "africa",
        lat: 6.611111,
        long: 20.939444,
        uuid: "3yD5diFnqmjjB9PzHmXcov",
        area: 622984,
        borders: [
            "cm",
            "td",
            "cd",
            "cg",
            "ss",
            "sd"
        ]
    },

    td: {
        name: "Chad",
        iso: "td",
        alt: "",
        continent: "africa",
        lat: 15.454166,
        long: 18.732207,
        uuid: "fpveyqRxQqn9s9Ug2tj2cA",
        area: 1284000,
        borders: [
            "cm",
            "cf",
            "ly",
            "ne",
            "ng",
            "sd"
        ]
    },

    cl: {
        name: "Chile",
        iso: "cl",
        alt: "",
        continent: "samerica",
        lat: -35.675147,
        long: -71.542969,
        uuid: "3kzU6hst6eneWTrA5SpBXw",
        area: 756102,
        borders: [
            "ar",
            "bo",
            "pe"
        ]
    },

    cn: {
        name: "China",
        iso: "cn",
        alt: "",
        continent: "asia",
        lat: 35.86166,
        long: 104.195397,
        uuid: "3XfqDjXn9TZj3t9m2u8mvA",
        area: 9706961,
        borders: [
            "af",
            "bt",
            "mm",
            "hk",
            "in",
            "kz",
            "np",
            "kp",
            "kg",
            "la",
            "mo",
            "mn",
            "pk",
            "ru",
            "tj",
            "vn"
        ]
    },

    cx: {
        name: "Christmas Island",
        iso: "cx",
        alt: "ChristmasIsland",
        continent: "asia",
        lat: -10.447525,
        long: 105.690449,
        uuid: "3QkqaWk3emqUcYDmUaYGVr",
        area: 135
    },

    cc: {
        name: "Cocos (Keeling) Islands",
        iso: "cc",
        alt: "Cocos(Keeling)Islands",
        continent: "asia",
        lat: -12.164165,
        long: 96.870956,
        uuid: "38ZDoamqN738cXXCKSWebz",
        area: 14
    },

    co: {
        name: "Colombia",
        iso: "co",
        alt: "",
        continent: "samerica",
        lat: 4.570868,
        long: -74.297333,
        uuid: "3ppxKRsghy4Kx6mJrEuksg",
        area: 1141748,
        borders: [
            "br",
            "ec",
            "pa",
            "pe",
            "ve"
        ]
    },

    km: {
        name: "Comoros",
        iso: "km",
        alt: "",
        continent: "africa",
        lat: -11.875001,
        long: 43.872219,
        uuid: "3LCQ9KrmA5HURvfDFjHjeg",
        area: 1862
    },

    ck: {
        name: "Cook Islands",
        iso: "ck",
        alt: "CookIslands",
        continent: "oceania",
        lat: -21.236736,
        long: -159.777671,
        uuid: "YrgmEK2XxDh7fRgq92ZeqS",
        area: 236
    },

    cr: {
        name: "Costa Rica",
        iso: "cr",
        alt: "CostaRica",
        continent: "namerica",
        lat: 9.748917,
        long: -83.753428,
        uuid: "huGjMPJwvR6J4zD3ZHrNYz",
        area: 51100,
        borders: [
            "ni",
            "pa"
        ]
    },

    hr: {
        name: "Croatia",
        iso: "hr",
        alt: "",
        continent: "europe",
        lat: 45.1,
        long: 15.2,
        uuid: "L34UfYTKFN4EzbhqqqBnWm",
        area: 56594,
        borders: [
            "ba",
            "hu",
            "me",
            "rs",
            "si"
        ]
    },

    cu: {
        name: "Cuba",
        iso: "cu",
        alt: "",
        continent: "namerica",
        lat: 21.521757,
        long: -77.781167,
        uuid: "3D3fZ63Hho6AAPuzRn9QvD",
        area: 109884
    },

    cw: {
        name: "Curaçao",
        iso: "cw",
        alt: "Curacao",
        continent: "namerica",
        lat: 12.1696,
        long: -68.99,
        uuid: "oJxLnXa2ahjfiQqQanpHxn",
        area: 444
    },

    cy: {
        name: "Cyprus",
        iso: "cy",
        alt: "",
        continent: "asia",
        lat: 35.126413,
        long: 33.429859,
        uuid: "BjrxVrc2M5kjwY2mbgC8vE",
        area: 9251
    },

    cz: {
        name: "Czechia",
        iso: "cz",
        alt: "",
        continent: "europe",
        lat: 49.817492,
        long: 15.472962,
        uuid: "Wh8sBVa4RgXcXXFyLKnbzG",
        area: 78865,
        borders: [
            "at",
            "de",
            "pl",
            "sk"
        ]
    },

    ci: {
        name: "Côte d'Ivoire (Ivory Coast)",
        iso: "ci",
        alt: "Cote d'Ivoire (Ivory Coast),Côted'Ivoire(IvoryCoast)",
        continent: "africa",
        lat: 7.539989,
        long: -5.54708,
        uuid: "3MMHgmTKYjKBuQqmug5iAd",
        area: 322463,
        borders: [
            "bf",
            "gh",
            "gn",
            "lr",
            "ml"
        ]
    },

    cd: {
        name: "Democratic Republic of the Congo",
        iso: "cd",
        alt: "DemocraticRepublicoftheCongo",
        continent: "africa",
        lat: -4.038333,
        long: 21.758664,
        uuid: "3ZH3uMLAaSBJ6UTgJBVAKB",
        area: 2344858,
        borders: [
            "ao",
            "bi",
            "cf",
            "cg",
            "rw",
            "ss",
            "tz",
            "ug",
            "zm"
        ]
    },

    dk: {
        name: "Denmark",
        iso: "dk",
        alt: "",
        continent: "europe",
        lat: 56.26392,
        long: 9.501785,
        uuid: "uZA9SDENWQPRGBQp44UZz9",
        area: 43094,
        borders: [
            "de"
        ]
    },

    dj: {
        name: "Djibouti",
        iso: "dj",
        alt: "",
        continent: "africa",
        lat: 11.825138,
        long: 42.590275,
        uuid: "3nPLrWrWBjGRxKYmRWh4ZF",
        area: 23200,
        borders: [
            "er",
            "et",
            "so"
        ]
    },

    dm: {
        name: "Dominica",
        iso: "dm",
        alt: "",
        continent: "namerica",
        lat: 15.414999,
        long: -61.370976,
        uuid: "36H4vdYYFaJG77fatJssxK",
        area: 751
    },

    do: {
        name: "Dominican Republic",
        iso: "do",
        alt: "DominicanRepublic",
        continent: "namerica",
        lat: 18.735693,
        long: -70.162651,
        uuid: "AyUs3EPXUF287wziHpy63n",
        area: 48671,
        borders: [
            "ht"
        ]
    },

    ec: {
        name: "Ecuador",
        iso: "ec",
        alt: "",
        continent: "samerica",
        lat: -1.831239,
        long: -78.183406,
        uuid: "5rJJpKajm6CoNkLAqZoFYs",
        area: 276841,
        borders: [
            "co",
            "pe"
        ]
    },

    eg: {
        name: "Egypt",
        iso: "eg",
        alt: "",
        continent: "africa",
        lat: 26.820553,
        long: 30.802498,
        uuid: "3Lw6q9NW2kVHhS6UU4yXgo",
        area: 1002450,
        borders: [
            "il",
            "ly",
            "ps",
            "sd"
        ]
    },

    sv: {
        name: "El Salvador",
        iso: "sv",
        alt: "ElSalvador",
        continent: "namerica",
        lat: 13.794185,
        long: -88.89653,
        uuid: "pqc4AK8PZe7KMtrgHjZdCR",
        area: 21041,
        borders: [
            "gt",
            "hn"
        ]
    },

    gbeng: {
        name: "England",
        iso: "gb-eng",
        alt: "",
        continent: "europe",
        lat: 52.3555,
        long: -1.1743,
        uuid: "QryvFvC7DqyzQynQw2hjxy",
        area: 130279,
        borders: [
            "gb-sct",
            "gb-wls"
        ],
        capitalCity: "London",
        population: 56286961,
        currency: "Pound sterling",
        language: "Cornish, English",
        googleMaps: "https://goo.gl/maps/xjybUFBGwgi2g4kY6"
    },

    gq: {
        name: "Equatorial Guinea",
        iso: "gq",
        alt: "EquatorialGuinea",
        continent: "africa",
        lat: 1.650801,
        long: 10.267895,
        uuid: "W85ZyYS7AKwVkXWmpqeVmK",
        area: 28051,
        borders: [
            "cm",
            "ga"
        ]
    },

    er: {
        name: "Eritrea",
        iso: "er",
        alt: "",
        continent: "africa",
        lat: 15.179384,
        long: 39.782334,
        uuid: "VBwYzv66q2REmtWSbtkwpp",
        area: 117600,
        borders: [
            "dj",
            "et",
            "sd"
        ]
    },

    ee: {
        name: "Estonia",
        iso: "ee",
        alt: "",
        continent: "europe",
        lat: 58.595272,
        long: 25.013607,
        uuid: "3W7sq2DjrgYxFVD2w6SSKe",
        area: 45227,
        borders: [
            "lv",
            "ru"
        ]
    },

    sz: {
        name: "Eswatini (Swaziland)",
        iso: "sz",
        alt: "Eswatini(Swaziland)",
        continent: "africa",
        lat: -26.522503,
        long: 31.465866,
        uuid: "SaJWULzQm9PYS9NTrkTiaq",
        area: 17364,
        borders: [
            "mz",
            "za"
        ]
    },

    et: {
        name: "Ethiopia",
        iso: "et",
        alt: "",
        continent: "africa",
        lat: 9.145,
        long: 40.489673,
        uuid: "3vH5y9KsG22vMMEjg9ymLQ",
        area: 1104300,
        borders: [
            "dj",
            "er",
            "ke",
            "so",
            "ss",
            "sd"
        ]
    },

    fk: {
        name: "Falkland Islands",
        iso: "fk",
        alt: "FalklandIslands",
        continent: "samerica",
        lat: -51.796253,
        long: -59.523613,
        uuid: "3wP2Ki2LiyLcQD6k4Ti3kN",
        area: 12173
    },

    fo: {
        name: "Faroe Islands",
        iso: "fo",
        alt: "FaroeIslands",
        continent: "europe",
        lat: 61.892635,
        long: -6.911806,
        uuid: "3W8Sd6LrYLHtNgTdUihjEQ",
        area: 1393
    },

    fj: {
        name: "Fiji",
        iso: "fj",
        alt: "",
        continent: "oceania",
        lat: -16.578193,
        long: 179.414413,
        uuid: "obFd94xU9pQmmdjwt4aGph",
        area: 18272
    },

    fi: {
        name: "Finland",
        iso: "fi",
        alt: "",
        continent: "europe",
        lat: 61.92411,
        long: 25.748151,
        uuid: "3Z9i2MW2bGzCuSuSmycSNr",
        area: 338424,
        borders: [
            "no",
            "se",
            "ru"
        ]
    },

    fr: {
        name: "France",
        iso: "fr",
        alt: "",
        continent: "europe",
        lat: 46.227638,
        long: 2.213749,
        uuid: "ayHV5kDdTh3VLHoh7EmL6z",
        area: 551695,
        borders: [
            "ad",
            "be",
            "de",
            "it",
            "lu",
            "mc",
            "es",
            "ch"
        ]
    },

    gf: {
        name: "French Guiana",
        iso: "gf",
        alt: "FrenchGuiana",
        continent: "samerica",
        lat: 3.933889,
        long: -53.125782,
        uuid: "3gzjKM3rSVyi5oPi9uTghk",
        area: 83534,
        borders: [
            "br",
            "sr"
        ]
    },

    pf: {
        name: "French Polynesia",
        iso: "pf",
        alt: "FrenchPolynesia",
        continent: "oceania",
        lat: -17.679742,
        long: -149.406843,
        uuid: "gQikeJQiTXqX2ByqjkixSy",
        area: 4167
    },

    tf: {
        name: "French Southern and Antarctic Lands",
        iso: "tf",
        alt: "FrenchSouthernandAntarcticLands",
        continent: "asia",
        lat: -49.280366,
        long: 69.348557,
        uuid: "3gahTvi9pCpL7icuve4qHY",
        area: 7747
    },

    ga: {
        name: "Gabon",
        iso: "ga",
        alt: "",
        continent: "africa",
        lat: -0.803689,
        long: 11.609444,
        uuid: "isvknic98H3aXV8geZKxL4",
        area: 267668,
        borders: [
            "cm",
            "cg",
            "gq"
        ]
    },

    gm: {
        name: "Gambia",
        iso: "gm",
        alt: "",
        continent: "africa",
        lat: 13.443182,
        long: -15.310139,
        uuid: "w4T8KLraKUjnnd6zZd3Mqy",
        area: 10689,
        borders: [
            "sn"
        ]
    },

    ge: {
        name: "Georgia",
        iso: "ge",
        alt: "",
        continent: "asia",
        lat: 42.315407,
        long: 43.356892,
        uuid: "NuCvthqaRKEQpNpndM7vL7",
        area: 69700,
        borders: [
            "am",
            "az",
            "ru",
            "tr"
        ]
    },

    de: {
        name: "Germany",
        iso: "de",
        alt: "",
        continent: "europe",
        lat: 51.165691,
        long: 10.451526,
        uuid: "3mmc3PLLr46Mdd9VXLmDnM",
        area: 357114,
        borders: [
            "at",
            "be",
            "cz",
            "dk",
            "fr",
            "lu",
            "nl",
            "pl",
            "ch"
        ]
    },

    gh: {
        name: "Ghana",
        iso: "gh",
        alt: "",
        continent: "africa",
        lat: 7.946527,
        long: -1.023194,
        uuid: "YDLg7vS7MafsYEdeJC3dgZ",
        area: 238533,
        borders: [
            "bf",
            "ci",
            "tg"
        ]
    },

    gi: {
        name: "Gibraltar",
        iso: "gi",
        alt: "",
        continent: "europe",
        lat: 36.137741,
        long: -5.345374,
        uuid: "3x2RAAfiZEUDZnirnZgmLA",
        area: 6,
        borders: [
            "es"
        ]
    },

    gr: {
        name: "Greece",
        iso: "gr",
        alt: "",
        continent: "europe",
        lat: 39.074208,
        long: 21.824312,
        uuid: "aLpEdzmV5F9dxUkcYvHGGJ",
        area: 131990,
        borders: [
            "al",
            "bg",
            "tr",
            "mk"
        ]
    },

    gl: {
        name: "Greenland",
        iso: "gl",
        alt: "",
        continent: "namerica",
        lat: 71.706936,
        long: -42.604303,
        uuid: "uehrt7yaksx5fZU6V9QEwF",
        area: 2166086
    },

    gd: {
        name: "Grenada",
        iso: "gd",
        alt: "",
        continent: "namerica",
        lat: 12.262776,
        long: -61.604171,
        uuid: "3XZgfjb3CgXaegYsCGeJQS",
        area: 344
    },

    gp: {
        name: "Guadeloupe",
        iso: "gp",
        alt: "",
        continent: "namerica",
        lat: 16.995971,
        long: -62.067641,
        uuid: "ASXECKPDtaHfoJYmRbDak6",
        area: 1628
    },

    gu: {
        name: "Guam",
        iso: "gu",
        alt: "",
        continent: "oceania",
        lat: 13.444304,
        long: 144.793731,
        uuid: "VTiUMYHnbuq3zxEY4SZhY2",
        area: 549
    },

    gt: {
        name: "Guatemala",
        iso: "gt",
        alt: "",
        continent: "namerica",
        lat: 15.783471,
        long: -90.230759,
        uuid: "xpzgjpXdoK7LRwAjCVMHYE",
        area: 108889,
        borders: [
            "bz",
            "sv",
            "hn",
            "mx"
        ]
    },

    gg: {
        name: "Guernsey",
        iso: "gg",
        alt: "",
        continent: "europe",
        lat: 49.465691,
        long: -2.585278,
        uuid: "g5ZhJxoCqBJ4DXDRLhLBNy",
        area: 78
    },

    gn: {
        name: "Guinea",
        iso: "gn",
        alt: "",
        continent: "africa",
        lat: 9.945587,
        long: -9.696645,
        uuid: "xGBpENPeCAeujN5JKNJvEU",
        area: 245857,
        borders: [
            "ci",
            "gw",
            "lr",
            "ml",
            "sn",
            "sl"
        ]
    },

    gw: {
        name: "Guinea-Bissau",
        iso: "gw",
        alt: "",
        continent: "africa",
        lat: 11.803749,
        long: -15.180413,
        uuid: "3BEJEFkgkr6RuS6wtW7nRC",
        area: 36125,
        borders: [
            "gn",
            "sn"
        ]
    },

    gy: {
        name: "Guyana",
        iso: "gy",
        alt: "",
        continent: "samerica",
        lat: 4.860416,
        long: -58.93018,
        uuid: "Wr9DeGvQvtZZkt8mqCXM6s",
        area: 214969,
        borders: [
            "br",
            "sr",
            "ve"
        ]
    },

    ht: {
        name: "Haiti",
        iso: "ht",
        alt: "",
        continent: "namerica",
        lat: 18.971187,
        long: -72.285215,
        uuid: "3GvZmWr6sTEW8kQWrLLb6i",
        area: 27750,
        borders: [
            "do"
        ]
    },

    hm: {
        name: "Heard Island and McDonald Islands",
        iso: "hm",
        alt: "HeardIslandandMcDonaldIslands",
        continent: "asia",
        lat: -53.08181,
        long: 73.504158,
        uuid: "3J95FAt8rDipsaRPiKmjNo",
        area: 412
    },

    hn: {
        name: "Honduras",
        iso: "hn",
        alt: "",
        continent: "namerica",
        lat: 15.199999,
        long: -86.241905,
        uuid: "3y6K6iiUNMR9DiDDxSk5ne",
        area: 112492,
        borders: [
            "gt",
            "sv",
            "ni"
        ]
    },

    hk: {
        name: "Hong Kong",
        iso: "hk",
        alt: "HongKong",
        continent: "asia",
        lat: 22.396428,
        long: 114.109497,
        uuid: "kjcLMJUsed7rir85v67ojF",
        area: 1104,
        borders: [
            "cn"
        ]
    },

    hu: {
        name: "Hungary",
        iso: "hu",
        alt: "",
        continent: "europe",
        lat: 47.162494,
        long: 19.503304,
        uuid: "wLnHGLE5JimGzhRFSSAgk4",
        area: 93028,
        borders: [
            "at",
            "hr",
            "ro",
            "rs",
            "sk",
            "si",
            "ua"
        ]
    },

    is: {
        name: "Iceland",
        iso: "is",
        alt: "",
        continent: "europe",
        lat: 64.963051,
        long: -19.020835,
        uuid: "3kNZ8RogawCnNxPL8BvsMx",
        area: 103000
    },

    in: {
        name: "India",
        iso: "in",
        alt: "",
        continent: "asia",
        lat: 20.593684,
        long: 78.96288,
        uuid: "fWtyfE39zuaM3fJ9SqzZek",
        area: 3287590,
        borders: [
            "bd",
            "bt",
            "mm",
            "cn",
            "np",
            "pk"
        ]
    },

    id: {
        name: "Indonesia",
        iso: "id",
        alt: "",
        continent: "asia",
        lat: -0.789275,
        long: 113.921327,
        uuid: "3oCjUd3rYEpEg2PuCSMUy4",
        area: 1904569,
        borders: [
            "tl",
            "my",
            "pg"
        ]
    },

    ir: {
        name: "Iran",
        iso: "ir",
        alt: "",
        continent: "asia",
        lat: 32.427908,
        long: 53.688046,
        uuid: "9YGQzy6EPhCLjV7p2sM68M",
        area: 1648195,
        borders: [
            "af",
            "am",
            "az",
            "iq",
            "pk",
            "tr",
            "tm"
        ]
    },

    iq: {
        name: "Iraq",
        iso: "iq",
        alt: "",
        continent: "asia",
        lat: 33.223191,
        long: 43.679291,
        uuid: "3kZZ89ihVTPWy5rmoreYax",
        area: 438317,
        borders: [
            "ir",
            "jo",
            "kw",
            "sa",
            "sy",
            "tr"
        ]
    },

    ie: {
        name: "Ireland",
        iso: "ie",
        alt: "",
        continent: "europe",
        lat: 53.41291,
        long: -8.24389,
        uuid: "CZeSgJuRSbNhpqyaBfm7gq",
        area: 70273,
        borders: [
            "gb",
            "gb-nir"
        ]
    },

    im: {
        name: "Isle of Man",
        iso: "im",
        alt: "IsleofMan",
        continent: "europe",
        lat: 54.236107,
        long: -4.548056,
        uuid: "3zXtmh63CZKFWxQaPi7bzH",
        area: 572
    },

    il: {
        name: "Israel",
        iso: "il",
        alt: "",
        continent: "asia",
        lat: 31.046051,
        long: 34.851612,
        uuid: "3nkULSp9EpjMmdxdBSowGt",
        area: 20770,
        borders: [
            "eg",
            "jo",
            "lb",
            "ps",
            "sy"
        ]
    },

    it: {
        name: "Italy",
        iso: "it",
        alt: "",
        continent: "europe",
        lat: 41.87194,
        long: 12.56738,
        uuid: "3wiTD5ncX8uFtRh8iNkyYf",
        area: 301336,
        borders: [
            "at",
            "fr",
            "sm",
            "si",
            "ch",
            "va"
        ]
    },

    jm: {
        name: "Jamaica",
        iso: "jm",
        alt: "",
        continent: "namerica",
        lat: 18.109581,
        long: -77.297508,
        uuid: "5Zwub3CLNnU8LEakqcWajb",
        area: 10991
    },

    jp: {
        name: "Japan",
        iso: "jp",
        alt: "",
        continent: "asia",
        lat: 36.204824,
        long: 138.252924,
        uuid: "9SR6ot5L23AXoyxpt7tpPi",
        area: 377930
    },

    je: {
        name: "Jersey",
        iso: "je",
        alt: "",
        continent: "europe",
        lat: 49.214439,
        long: -2.13125,
        uuid: "3rAPntnW4GiX9cGTUmM4Pd",
        area: 116
    },

    jo: {
        name: "Jordan",
        iso: "jo",
        alt: "",
        continent: "asia",
        lat: 30.585164,
        long: 36.238414,
        uuid: "RRYQFzUmkrJMLVvdeXNaXt",
        area: 89342,
        borders: [
            "iq",
            "il",
            "ps",
            "sa",
            "sy"
        ]
    },

    kz: {
        name: "Kazakhstan",
        iso: "kz",
        alt: "",
        continent: "asia",
        lat: 48.019573,
        long: 66.923684,
        uuid: "42zLuevQcdEFcE4ncEVRoZ",
        area: 2724900,
        borders: [
            "cn",
            "kg",
            "ru",
            "tm",
            "uz"
        ]
    },

    ke: {
        name: "Kenya",
        iso: "ke",
        alt: "",
        continent: "africa",
        lat: -0.023559,
        long: 37.906193,
        uuid: "GiBNY6TBVZgUiBWroPNg3a",
        area: 580367,
        borders: [
            "et",
            "so",
            "ss",
            "tz",
            "ug"
        ]
    },

    ki: {
        name: "Kiribati",
        iso: "ki",
        alt: "",
        continent: "oceania",
        lat: -3.370417,
        long: -168.734039,
        uuid: "fbuFJtS2KjvmxoXiK7vYch",
        area: 811
    },

    xk: {
        name: "Kosovo",
        iso: "xk",
        alt: "",
        continent: "europe",
        lat: 42.602636,
        long: 20.902977,
        uuid: "3CB87Etv8qCfCow6YMdvT3",
        area: 10908,
        borders: [
            "al",
            "mk",
            "me",
            "rs"
        ]
    },

    kw: {
        name: "Kuwait",
        iso: "kw",
        alt: "",
        continent: "asia",
        lat: 29.31166,
        long: 47.481766,
        uuid: "3acr9TyLW9BHWBzC9ZjSR6",
        area: 17818,
        borders: [
            "iq",
            "sa"
        ]
    },

    kg: {
        name: "Kyrgyzstan",
        iso: "kg",
        alt: "",
        continent: "asia",
        lat: 41.20438,
        long: 74.766098,
        uuid: "jj9QZno4RhrjpvD9ruKuqG",
        area: 199951,
        borders: [
            "cn",
            "kz",
            "tj",
            "uz"
        ]
    },

    la: {
        name: "Laos",
        iso: "la",
        alt: "",
        continent: "asia",
        lat: 19.85627,
        long: 102.495496,
        uuid: "3g2UwjvsE822FFiDLD2Af4",
        area: 236800,
        borders: [
            "mm",
            "kh",
            "cn",
            "th",
            "vn"
        ]
    },

    lv: {
        name: "Latvia",
        iso: "lv",
        alt: "",
        continent: "europe",
        lat: 56.879635,
        long: 24.603189,
        uuid: "x27tTKjAghAHGZRGNnAhwb",
        area: 64559,
        borders: [
            "by",
            "ee",
            "lt",
            "ru"
        ]
    },

    lb: {
        name: "Lebanon",
        iso: "lb",
        alt: "",
        continent: "asia",
        lat: 33.854721,
        long: 35.862285,
        uuid: "3LHg58QRPsUq8fwhZoE9XQ",
        area: 10452,
        borders: [
            "il",
            "sy"
        ]
    },

    ls: {
        name: "Lesotho",
        iso: "ls",
        alt: "",
        continent: "africa",
        lat: -29.609988,
        long: 28.233608,
        uuid: "3cJtJfRXkWjFN224c8LaXj",
        area: 30355,
        borders: [
            "za"
        ]
    },

    lr: {
        name: "Liberia",
        iso: "lr",
        alt: "",
        continent: "africa",
        lat: 6.428055,
        long: -9.429499,
        uuid: "jJkiRZcECLQEr2Q9MxMheJ",
        area: 111369,
        borders: [
            "gn",
            "ci",
            "sl"
        ]
    },

    ly: {
        name: "Libya",
        iso: "ly",
        alt: "",
        continent: "africa",
        lat: 26.3351,
        long: 17.228331,
        uuid: "KaBrPzHjYdJrMZX247dEDN",
        area: 1759540,
        borders: [
            "dz",
            "td",
            "eg",
            "ne",
            "sd",
            "tn"
        ]
    },

    li: {
        name: "Liechtenstein",
        iso: "li",
        alt: "",
        continent: "europe",
        lat: 47.166,
        long: 9.555373,
        uuid: "3zmGj8e3TipiA9hrLpvPy6",
        area: 160,
        borders: [
            "at",
            "ch"
        ]
    },

    lt: {
        name: "Lithuania",
        iso: "lt",
        alt: "",
        continent: "europe",
        lat: 55.169438,
        long: 23.881275,
        uuid: "ekkgkH3cHqnmRqzKMU4aCb",
        area: 65300,
        borders: [
            "by",
            "lv",
            "pl",
            "ru"
        ]
    },

    lu: {
        name: "Luxembourg",
        iso: "lu",
        alt: "",
        continent: "europe",
        lat: 49.815273,
        long: 6.129583,
        uuid: "3aBvd6fYZi79ipLQeEVTMc",
        area: 2586,
        borders: [
            "be",
            "fr",
            "de"
        ]
    },

    mo: {
        name: "Macau",
        iso: "mo",
        alt: "",
        continent: "asia",
        lat: 22.198745,
        long: 113.543873,
        uuid: "3HAmQ225Y5MdpDqamVV4wP",
        area: 30,
        borders: [
            "cn"
        ]
    },

    mg: {
        name: "Madagascar",
        iso: "mg",
        alt: "",
        continent: "africa",
        lat: -18.766947,
        long: 46.869107,
        uuid: "pdbcefmpmkXchcS62ZWJqD",
        area: 587041
    },

    mw: {
        name: "Malawi",
        iso: "mw",
        alt: "",
        continent: "africa",
        lat: -13.254308,
        long: 34.301525,
        uuid: "3eR5WttHBj4Lxf7By5quJ5",
        area: 118484,
        borders: [
            "mz",
            "tz",
            "zm"
        ]
    },

    my: {
        name: "Malaysia",
        iso: "my",
        alt: "",
        continent: "asia",
        lat: 4.210484,
        long: 101.975766,
        uuid: "38ngNFxLkLgapGnxdwtCoS",
        area: 330803,
        borders: [
            "bn",
            "id",
            "th"
        ]
    },

    mv: {
        name: "Maldives",
        iso: "mv",
        alt: "",
        continent: "asia",
        lat: 3.202778,
        long: 73.22068,
        uuid: "8RgkFXpcJqe9tTNLxVkQ2G",
        area: 300
    },

    ml: {
        name: "Mali",
        iso: "ml",
        alt: "",
        continent: "africa",
        lat: 17.570692,
        long: -3.996166,
        uuid: "3h52hLSwBVnYjiMP3Z8VRt",
        area: 1240192,
        borders: [
            "dz",
            "bf",
            "gn",
            "ci",
            "mr",
            "ne",
            "sn"
        ]
    },

    mt: {
        name: "Malta",
        iso: "mt",
        alt: "",
        continent: "europe",
        lat: 35.937496,
        long: 14.375416,
        uuid: "3qz3SSFwNhbUtpsFyWyiEY",
        area: 316
    },

    mh: {
        name: "Marshall Islands",
        iso: "mh",
        alt: "",
        continent: "oceania",
        lat: 7.131474,
        long: 171.184478,
        uuid: "7PjBHdWvACiCs6ChZWLAbR",
        area: 181
    },

    mq: {
        name: "Martinique",
        iso: "mq",
        alt: "",
        continent: "namerica",
        lat: 14.641528,
        long: -61.024174,
        uuid: "3D3Wpr7y9L2i7DrkDqunTV",
        area: 1128
    },

    mr: {
        name: "Mauritania",
        iso: "mr",
        alt: "",
        continent: "africa",
        lat: 21.00789,
        long: -10.940835,
        uuid: "jcLyNhxWxME2BKcyfVwCwW",
        area: 1030700,
        borders: [
            "dz",
            "ml",
            "sn",
            "eh"
        ]
    },

    mu: {
        name: "Mauritius",
        iso: "mu",
        alt: "",
        continent: "africa",
        lat: -20.348404,
        long: 57.552152,
        uuid: "7FmUTLFfiEcmRqYC755xrF",
        area: 2040
    },

    yt: {
        name: "Mayotte",
        iso: "yt",
        alt: "",
        continent: "africa",
        lat: -12.8275,
        long: 45.166244,
        uuid: "f9mTTuBzXfV9onW7nBC6fQ",
        area: 374
    },

    mx: {
        name: "Mexico",
        iso: "mx",
        alt: "",
        continent: "namerica",
        lat: 23.634501,
        long: -102.552784,
        uuid: "3Ra9TBy7x8ouYXy8udLfY6",
        area: 1964375,
        borders: [
            "bz",
            "gt",
            "us"
        ]
    },

    fm: {
        name: "Micronesia",
        iso: "fm",
        alt: "",
        continent: "oceania",
        lat: 7.425554,
        long: 150.550812,
        uuid: "WDvw7PtRn5XTbdsaTWCLoN",
        area: 702
    },

    md: {
        name: "Moldova",
        iso: "md",
        alt: "",
        continent: "europe",
        lat: 47.411631,
        long: 28.369885,
        uuid: "PH8coLT6Gxw4wUGSApj8do",
        area: 33846,
        borders: [
            "ro",
            "ua"
        ]
    },

    mc: {
        name: "Monaco",
        iso: "mc",
        alt: "",
        continent: "europe",
        lat: 43.750298,
        long: 7.412841,
        uuid: "3pqJAgrTMRWfb8C6ycTZ93",
        area: 2.02,
        borders: [
            "fr"
        ]
    },

    mn: {
        name: "Mongolia",
        iso: "mn",
        alt: "",
        continent: "asia",
        lat: 46.862496,
        long: 103.846656,
        uuid: "jZDDqAJ2RBkTnTxeFS2Gjj",
        area: 1564110,
        borders: [
            "cn",
            "ru"
        ]
    },

    me: {
        name: "Montenegro",
        iso: "me",
        alt: "",
        continent: "europe",
        lat: 42.708678,
        long: 19.37439,
        uuid: "KHTmy7JjVFABWQsnsbmRjA",
        area: 13812,
        borders: [
            "al",
            "ba",
            "hr",
            "xk",
            "rs"
        ]
    },

    ms: {
        name: "Montserrat",
        iso: "ms",
        alt: "",
        continent: "namerica",
        lat: 16.742498,
        long: -62.187366,
        uuid: "3ekoYbVJpz45poL8YJtwZ7",
        area: 102
    },

    ma: {
        name: "Morocco",
        iso: "ma",
        alt: "",
        continent: "africa",
        lat: 31.791702,
        long: -7.09262,
        uuid: "3ersTMzeeynwCjyY3Ay3qw",
        area: 446550,
        borders: [
            "dz",
            "eh",
            "es"
        ]
    },

    mz: {
        name: "Mozambique",
        iso: "mz",
        alt: "",
        continent: "africa",
        lat: -18.665695,
        long: 35.529562,
        uuid: "3N7wdg9znHy8vU7haRk9Wx",
        area: 801590,
        borders: [
            "mw",
            "za",
            "sz",
            "tz",
            "zm",
            "zw"
        ]
    },

    mm: {
        name: "Myanmar",
        iso: "mm",
        alt: "",
        continent: "asia",
        lat: 21.913965,
        long: 95.956223,
        uuid: "3tLSKD7cBZZEXXkEPjnYfn",
        area: 676578,
        borders: [
            "bd",
            "cn",
            "in",
            "la",
            "th"
        ]
    },

    na: {
        name: "Namibia",
        iso: "na",
        alt: "",
        continent: "africa",
        lat: -22.95764,
        long: 18.49041,
        uuid: "VeGNHNp4TRae3iJbD3NSN3",
        area: 825615,
        borders: [
            "ao",
            "bw",
            "za",
            "zm"
        ]
    },

    nr: {
        name: "Nauru",
        iso: "nr",
        alt: "",
        continent: "oceania",
        lat: -0.522778,
        long: 166.931503,
        uuid: "qSXwy6VZ4dSfNR6ca4Skqs",
        area: 21
    },

    np: {
        name: "Nepal",
        iso: "np",
        alt: "",
        continent: "asia",
        lat: 28.394857,
        long: 84.124008,
        uuid: "NLKjgL8WnUW6PjNDYkzBsf",
        area: 147181,
        borders: [
            "cn",
            "in"
        ]
    },

    nl: {
        name: "Netherlands",
        iso: "nl",
        alt: "",
        continent: "europe",
        lat: 52.132633,
        long: 5.291266,
        uuid: "3Mwjn6FVwpDGKBKTZndoj5",
        area: 41850,
        borders: [
            "be",
            "de"
        ]
    },

    nc: {
        name: "New Caledonia",
        iso: "nc",
        alt: "NewCaledonia",
        continent: "asia",
        lat: -20.904305,
        long: 165.618042,
        uuid: "3w37wYTVPjvyRqSvFmQwg3",
        area: 18575
    },

    nz: {
        name: "New Zealand",
        iso: "nz",
        alt: "NewZealand",
        continent: "oceania",
        lat: -40.900557,
        long: 174.885971,
        uuid: "3uqipAkxyPjMLJ45rP7YnG",
        area: 270467
    },

    ni: {
        name: "Nicaragua",
        iso: "ni",
        alt: "",
        continent: "namerica",
        lat: 12.865416,
        long: -85.207229,
        uuid: "5F3pm8LnGBFFwcsNHmEaUu",
        area: 130373,
        borders: [
            "cr",
            "hn"
        ]
    },

    ne: {
        name: "Niger",
        iso: "ne",
        alt: "",
        continent: "africa",
        lat: 17.607789,
        long: 8.081666,
        uuid: "a4WiWGdy8DmjiXFKhNZ3Bs",
        area: 1267000,
        borders: [
            "dz",
            "bj",
            "bf",
            "td",
            "ly",
            "ml",
            "ng"
        ]
    },

    ng: {
        name: "Nigeria",
        iso: "ng",
        alt: "",
        continent: "africa",
        lat: 9.081999,
        long: 8.675277,
        uuid: "dFsjbCWwcKPx8EuhPkBaFN",
        area: 923768,
        borders: [
            "bj",
            "cm",
            "td",
            "ne"
        ]
    },

    nu: {
        name: "Niue",
        iso: "nu",
        alt: "",
        continent: "oceania",
        lat: -19.054445,
        long: -169.867233,
        uuid: "JYoLDEEn7FZqJGtVbzGGCZ",
        area: 260
    },

    nf: {
        name: "Norfolk Island",
        iso: "nf",
        alt: "NorfolkIsland",
        continent: "oceania",
        lat: -29.040835,
        long: 167.954712,
        uuid: "3bwfxZhcVdpgF73DJJezft",
        area: 36
    },

    kp: {
        name: "North Korea",
        iso: "kp",
        alt: "NorthKorea",
        continent: "asia",
        lat: 40.339852,
        long: 127.510093,
        uuid: "3cubig8fMcW3YJjeWaUdcN",
        area: 120538,
        borders: [
            "cn",
            "kr",
            "ru"
        ]
    },

    mk: {
        name: "North Macedonia",
        iso: "mk",
        alt: "NorthMacedonia",
        continent: "europe",
        lat: 41.608635,
        long: 21.745275,
        uuid: "AHdzizM4S53aaeyNqGv466",
        area: 25713,
        borders: [
            "al",
            "bg",
            "gr",
            "xk",
            "rs"
        ]
    },

    gbnir: {
        name: "Northern Ireland",
        iso: "gb-nir",
        alt: "",
        continent: "europe",
        lat: 54.7877,
        long: -6.4923,
        uuid: "343h4LKMTvn9iokRWQrgLZ",
        area: 14130,
        borders: [
            "ie"
        ],
        capitalCity: "Belfast",
        population: 1900000,
        currency: "Pound sterling",
        language: "English, Irish",
        googleMaps: "https://goo.gl/maps/4phKxKmKGJJHzJ896"
    },

    mp: {
        name: "Northern Mariana Islands",
        iso: "mp",
        alt: "",
        continent: "oceania",
        lat: 17.33083,
        long: 145.38469,
        uuid: "3K5qYsm2wqoPZLuTHgYTKf",
        area: 464
    },

    no: {
        name: "Norway",
        iso: "no",
        alt: "",
        continent: "europe",
        lat: 60.472024,
        long: 8.468946,
        uuid: "3Rf5hfMVFXm5Pxu27XRugh",
        area: 323802,
        borders: [
            "fi",
            "se",
            "ru"
        ]
    },

    om: {
        name: "Oman",
        iso: "om",
        alt: "",
        continent: "asia",
        lat: 21.512583,
        long: 55.923255,
        uuid: "nFNz4RgcjtWDNdnY4DM2b8",
        area: 309500,
        borders: [
            "sa",
            "ae",
            "ye"
        ]
    },

    pk: {
        name: "Pakistan",
        iso: "pk",
        alt: "",
        continent: "asia",
        lat: 30.375321,
        long: 69.345116,
        uuid: "StRtYTQMhXcyKgw6vvRoJn",
        area: 881912,
        borders: [
            "af",
            "cn",
            "in",
            "ir"
        ]
    },

    pw: {
        name: "Palau",
        iso: "pw",
        alt: "",
        continent: "oceania",
        lat: 7.51498,
        long: 134.58252,
        uuid: "riRSFTdpy3BQAizL3mkX4x",
        area: 459
    },

    ps: {
        name: "Palestine",
        iso: "ps",
        alt: "",
        continent: "asia",
        lat: 31.952162,
        long: 35.233154,
        uuid: "AjjFXNnTruCwU39YsESyyr",
        area: 6220,
        borders: [
            "il",
            "eg",
            "jo"
        ]
    },

    pa: {
        name: "Panama",
        iso: "pa",
        alt: "",
        continent: "namerica",
        lat: 8.537981,
        long: -80.782127,
        uuid: "3ARLtWbT82zveu6kMfk94x",
        area: 75417,
        borders: [
            "co",
            "cr"
        ]
    },

    pg: {
        name: "Papua New Guinea",
        iso: "pg",
        alt: "PapuaNewGuinea",
        continent: "oceania",
        lat: -6.314993,
        long: 143.95555,
        uuid: "3nEEsu7GaWYTzBLvZob8AG",
        area: 462840,
        borders: [
            "id"
        ]
    },

    py: {
        name: "Paraguay",
        iso: "py",
        alt: "",
        continent: "samerica",
        lat: -23.442503,
        long: -58.443832,
        uuid: "pjiGK935twbrxxs24gbHer",
        area: 406752,
        borders: [
            "ar",
            "bo",
            "br"
        ]
    },

    pe: {
        name: "Peru",
        iso: "pe",
        alt: "",
        continent: "samerica",
        lat: -9.189967,
        long: -75.015152,
        uuid: "5mtvryxxDMNtsGBkVijiZb",
        area: 1285216,
        borders: [
            "bo",
            "br",
            "cl",
            "co",
            "ec"
        ]
    },

    ph: {
        name: "Philippines",
        iso: "ph",
        alt: "",
        continent: "asia",
        lat: 12.879721,
        long: 121.774017,
        uuid: "LVhMbc4nS8h85PQuk5AUmk",
        area: 342353
    },

    pn: {
        name: "Pitcairn Islands",
        iso: "pn",
        alt: "PitcairnIslands",
        continent: "oceania",
        lat: -24.703615,
        long: -127.439308,
        uuid: "Jo7Ehi2GtWuYARXQqTDQWx",
        area: 47
    },

    pl: {
        name: "Poland",
        iso: "pl",
        alt: "",
        continent: "europe",
        lat: 51.919438,
        long: 19.145136,
        uuid: "B5paNTiRtXSnpuDdcM3gTG",
        area: 312679,
        borders: [
            "by",
            "cz",
            "de",
            "lt",
            "ru",
            "sk",
            "ua"
        ]
    },

    pt: {
        name: "Portugal",
        iso: "pt",
        alt: "",
        continent: "europe",
        lat: 39.399872,
        long: -8.224454,
        uuid: "3BukmBjeJdgnLDffggBMm7",
        area: 92090,
        borders: [
            "es"
        ]
    },

    pr: {
        name: "Puerto Rico",
        iso: "pr",
        alt: "PuertoRico",
        continent: "namerica",
        lat: 18.220833,
        long: -66.590149,
        uuid: "P3DXkBjXm3tWknTxNqsrwm",
        area: 8870
    },

    qa: {
        name: "Qatar",
        iso: "qa",
        alt: "",
        continent: "asia",
        lat: 25.354826,
        long: 51.183884,
        uuid: "nXhDFPzQnX3bReHs7LkqDS",
        area: 11586,
        borders: [
            "sa"
        ]
    },

    cg: {
        name: "Republic of the Congo",
        iso: "cg",
        alt: "RepublicoftheCongo",
        continent: "africa",
        lat: -0.228021,
        long: 15.827659,
        uuid: "njagf7ZBMe57Mcfz6CkWz5",
        area: 342000,
        borders: [
            "ao",
            "cm",
            "cf",
            "cd",
            "ga"
        ]
    },

    ro: {
        name: "Romania",
        iso: "ro",
        alt: "",
        continent: "europe",
        lat: 45.943161,
        long: 24.96676,
        uuid: "3t4QrziRguwquTKJuRvnJD",
        area: 238391,
        borders: [
            "bg",
            "hu",
            "md",
            "rs",
            "ua"
        ]
    },
    
    ru: {
        name: "Russia",
        iso: "ru",
        alt: "",
        continent: "europe",
        lat: 61.52401,
        long: 105.318756,
        uuid: "PxnkJkPZzWKzeKHF2SjdDN",
        area: 17098242,
        borders: [
            "az",
            "by",
            "cn",
            "ee",
            "fi",
            "ge",
            "kz",
            "kp",
            "lv",
            "lt",
            "mn",
            "no",
            "pl",
            "ua"
        ]
    },

    rw: {
        name: "Rwanda",
        iso: "rw",
        alt: "",
        continent: "africa",
        lat: -1.940278,
        long: 29.873888,
        uuid: "5BDKu5MgrjVdsGuAmpcQau",
        area: 26338,
        borders: [
            "bi",
            "cd",
            "tz",
            "ug"
        ]
    },

    re: {
        name: "Réunion",
        iso: "re",
        alt: "Reunion",
        continent: "africa",
        lat: -21.115141,
        long: 55.536384,
        uuid: "3LmjhmR2GU4Yx2unFvyW9N",
        area: 2511
    },

    bl: {
        name: "Saint Barthélemy",
        iso: "bl",
        alt: "Saint Barthelemy,SaintBarthélemy",
        continent: "namerica",
        lat: 17.9,
        long: -62.8333,
        uuid: "3SAUsEu5jAAdMLgUHmwo4r",
        area: 21
    },

    sh: {
        name: "Saint Helena, Ascension and Tristan da Cunha",
        iso: "sh",
        alt: "SaintHelena,AscensionandTristandaCunha",
        continent: "africa",
        lat: -24.143474,
        long: -10.030696,
        uuid: "3tfuXVW4EG35vS5yrnvUAZ",
        area: 394
    },

    kn: {
        name: "Saint Kitts and Nevis",
        iso: "kn",
        alt: "SaintKittsandNevis",
        continent: "namerica",
        lat: 17.357822,
        long: -62.782998,
        uuid: "BX6JKDLbWscgSGgvNvUhh4",
        area: 261
    },

    lc: {
        name: "Saint Lucia",
        iso: "lc",
        alt: "SaintLucia",
        continent: "namerica",
        lat: 13.909444,
        long: -60.978893,
        uuid: "H5bZtNh7ABdXJqm57Cu8if",
        area: 616
    },

    mf: {
        name: "Saint Martin",
        iso: "mf",
        alt: "SaintMartin",
        continent: "namerica",
        lat: 18.0708,
        long: -63.0501,
        uuid: "3hjLyTSJG5fUbaVrFUXj7H",
        area: 53,
        borders: [
            "sx"
        ]
    },

    pm: {
        name: "Saint Pierre and Miquelon",
        iso: "pm",
        alt: "SaintPierreandMiquelon",
        continent: "namerica",
        lat: 46.941936,
        long: -56.27111,
        uuid: "3q3BTSvXDDVDBPZ5VXh6dZ",
        area: 242
    },

    vc: {
        name: "Saint Vincent and the Grenadines",
        iso: "vc",
        alt: "SaintVincentandtheGrenadines",
        continent: "namerica",
        lat: 12.984305,
        long: -61.287228,
        uuid: "EDsfi8Pi9zt2mDB4MQFNkW",
        area: 389
    },

    ws: {
        name: "Samoa",
        iso: "ws",
        alt: "",
        continent: "oceania",
        lat: -13.759029,
        long: -172.104629,
        uuid: "6yitw3K3mmqzVxzEFAS3sa",
        area: 2842
    },

    sm: {
        name: "San Marino",
        iso: "sm",
        alt: "SanMarino",
        continent: "europe",
        lat: 43.94236,
        long: 12.457777,
        uuid: "GzDCmLaTGggDotx7P4Arrm",
        area: 61,
        borders: [
            "it"
        ]
    },

    sa: {
        name: "Saudi Arabia",
        iso: "sa",
        alt: "SaudiArabia",
        continent: "asia",
        lat: 23.885942,
        long: 45.079162,
        uuid: "YDZLUgUoEsPyuP7GjRSNc2",
        area: 2149690,
        borders: [
            "iq",
            "jo",
            "kw",
            "om",
            "qa",
            "ae",
            "ye"
        ]
    },

    gbsct: {
        name: "Scotland",
        iso: "gb-sct",
        alt: "",
        continent: "europe",
        lat: 56.4907,
        long: -4.2026,
        uuid: "BiRjBePVWhZwtXZBj9CRr9",
        area: 77933,
        borders: [
            "gb-eng"
        ],
        capitalCity: "Edinburgh",
        population: 5463300,
        currency: "Pound sterling",
        language: "English, Scottish Gaelic",
        googleMaps: "https://goo.gl/maps/EsJZDavVWwSQdmPf7"
    },

    sn: {
        name: "Senegal",
        iso: "sn",
        alt: "",
        continent: "africa",
        lat: 14.497401,
        long: -14.452362,
        uuid: "ddcKhF5yvDKPDiE9wWd32Z",
        area: 196722,
        borders: [
            "gm",
            "gn",
            "gw",
            "ml",
            "mr"
        ]
    },

    rs: {
        name: "Serbia",
        iso: "rs",
        alt: "",
        continent: "europe",
        lat: 44.016521,
        long: 21.005859,
        uuid: "3rDgGtPmonNPGniaygXPVf",
        area: 88361,
        borders: [
            "ba",
            "bg",
            "hr",
            "hu",
            "xk",
            "mk",
            "me",
            "ro"
        ]
    },

    sc: {
        name: "Seychelles",
        iso: "sc",
        alt: "",
        continent: "africa",
        lat: -4.679574,
        long: 55.491977,
        uuid: "Mi7bgwqcLjBkrBprfLYkYL",
        area: 452
    },

    sl: {
        name: "Sierra Leone",
        iso: "sl",
        alt: "SierraLeone",
        continent: "africa",
        lat: 8.460555,
        long: -11.779889,
        uuid: "3Fo5MWYAdqmepQtYFviFC6",
        area: 71740,
        borders: [
            "gn",
            "lr"
        ]
    },

    sg: {
        name: "Singapore",
        iso: "sg",
        alt: "",
        continent: "asia",
        lat: 1.352083,
        long: 103.819836,
        uuid: "3GaNVmtrYzFk2gEEksiSK9",
        area: 710
    },

    sx: {
        name: "Sint Maarten",
        iso: "sx",
        alt: "SintMaarten",
        continent: "namerica",
        lat: 18.0425,
        long: -63.0548,
        uuid: "3u8dTBubt4pX5ePhWDytRo",
        area: 34,
        borders: [
            "mf"
        ]
    },

    sk: {
        name: "Slovakia",
        iso: "sk",
        alt: "",
        continent: "europe",
        lat: 48.669026,
        long: 19.699024,
        uuid: "N3UnByy4hDxME5zPBE2QxX",
        area: 49037,
        borders: [
            "at",
            "cz",
            "hu",
            "pl",
            "ua"
        ]
    },

    si: {
        name: "Slovenia",
        iso: "si",
        alt: "",
        continent: "europe",
        lat: 46.151241,
        long: 14.995463,
        uuid: "3RVfjURfSk4tJp6QP2b3Et",
        area: 20273,
        borders: [
            "at",
            "hr",
            "it",
            "hu"
        ]
    },

    sb: {
        name: "Solomon Islands",
        iso: "sb",
        alt: "SolomonIslands",
        continent: "oceania",
        lat: -9.64571,
        long: 160.156194,
        uuid: "C9qSULVmhwGBWQ7CTuCChn",
        area: 28896
    },

    so: {
        name: "Somalia",
        iso: "so",
        alt: "",
        continent: "africa",
        lat: 5.152149,
        long: 46.199616,
        uuid: "3bDubiNKjboxCKY7zCx67X",
        area: 637657,
        borders: [
            "dj",
            "et",
            "ke"
        ]
    },

    za: {
        name: "South Africa",
        iso: "za",
        alt: "SouthAfrica",
        continent: "africa",
        lat: -30.559482,
        long: 22.937506,
        uuid: "UvHPigAYAJ87fwMmyjHfNa",
        area: 1221037,
        borders: [
            "bw",
            "ls",
            "mz",
            "na",
            "sz",
            "zw"
        ]
    },

    gs: {
        name: "South Georgia",
        iso: "gs",
        alt: "SouthGeorgia",
        continent: "asia",
        lat: -54.429579,
        long: -36.587909,
        uuid: "errNu2kx6eNte8TvakZgtd",
        area: 3903
    },

    kr: {
        name: "South Korea",
        iso: "kr",
        alt: "SouthKorea",
        continent: "asia",
        lat: 35.907757,
        long: 127.766922,
        uuid: "qgkrQ7FK3tkTb7SCPbmUwE",
        area: 100210,
        borders: [
            "kp"
        ]
    },

    ss: {
        name: "South Sudan",
        iso: "ss",
        alt: "SouthSudan",
        continent: "africa",
        lat: 6.877,
        long: 31.307,
        uuid: "3REDxVXxCyLQCPRg89DSr3",
        area: 619745,
        borders: [
            "cf",
            "cd",
            "et",
            "ke",
            "sd",
            "ug"
        ]
    },

    es: {
        name: "Spain",
        iso: "es",
        alt: "",
        continent: "europe",
        lat: 40.463667,
        long: -3.74922,
        uuid: "eUP8AVNWpdN9ab6VEUHuB3",
        area: 505992,
        borders: [
            "ad",
            "fr",
            "gi",
            "pt",
            "ma"
        ]
    },

    lk: {
        name: "Sri Lanka",
        iso: "lk",
        alt: "SriLanka",
        continent: "asia",
        lat: 7.873054,
        long: 80.771797,
        uuid: "3HEjtPxXcbXMYQBzxAqgGP",
        area: 65610,
        borders: [
            "in"
        ]
    },

    sd: {
        name: "Sudan",
        iso: "sd",
        alt: "",
        continent: "africa",
        lat: 12.862807,
        long: 30.217636,
        uuid: "i7TvbtF9aZMdnNsyZp3Je7",
        area: 1886068,
        borders: [
            "cf",
            "td",
            "eg",
            "er",
            "et",
            "ly",
            "ss"
        ]
    },

    sr: {
        name: "Suriname",
        iso: "sr",
        alt: "",
        continent: "asia",
        lat: 3.919305,
        long: -56.027783,
        uuid: "3YTixcAbVsDqujQWjcPKL7",
        area: 163820,
        borders: [
            "br",
            "gf",
            "gy"
        ]
    },

    sj: {
        name: "Svalbard and Jan Mayen",
        iso: "sj",
        alt: "SvalbardandJanMayen",
        continent: "europe",
        lat: 77.553604,
        long: 23.670272,
        uuid: "aZ3qNvVNhPVeBwtDn879X6",
        area: -1
    },

    se: {
        name: "Sweden",
        iso: "se",
        alt: "",
        continent: "europe",
        lat: 60.128161,
        long: 18.643501,
        uuid: "yCXVkueYZtEGt7wzY6u6ag",
        area: 450295,
        borders: [
            "fi",
            "no"
        ]
    },

    ch: {
        name: "Switzerland",
        iso: "ch",
        alt: "",
        continent: "europe",
        lat: 46.818188,
        long: 8.227512,
        uuid: "kWaPLFhRYQh7ujKaffkSxU",
        area: 41284,
        borders: [
            "at",
            "fr",
            "it",
            "li",
            "de"
        ]
    },

    sy: {
        name: "Syria",
        iso: "sy",
        alt: "",
        continent: "asia",
        lat: 34.802075,
        long: 38.996815,
        uuid: "ZvXKcAaYYXZVQkuQsNYJ5T",
        area: 185180,
        borders: [
            "iq",
            "il",
            "jo",
            "lb",
            "tr"
        ]
    },

    st: {
        name: "São Tomé and Príncipe",
        iso: "st",
        alt: "SãoToméandPríncipe,Sao Tome and Principe,SaoTomeandPrincipe",
        continent: "africa",
        lat: 0.18636,
        long: 6.613081,
        uuid: "EkFaXW2xhYoPySdhojueBU",
        area: 964
    },

    tw: {
        name: "Taiwan",
        iso: "tw",
        alt: "",
        continent: "asia",
        lat: 23.69781,
        long: 120.960515,
        uuid: "3ivHe3Zaysjkf2MPKKNQ8h",
        area: 36193
    },

    tj: {
        name: "Tajikistan",
        iso: "tj",
        alt: "",
        continent: "asia",
        lat: 38.861034,
        long: 71.276093,
        uuid: "37fNtGDiaSnwwPnraJb2AC",
        area: 143100,
        borders: [
            "af",
            "cn",
            "kg",
            "uz"
        ]
    },

    tz: {
        name: "Tanzania",
        iso: "tz",
        alt: "",
        continent: "africa",
        lat: -6.369028,
        long: 34.888822,
        uuid: "nvTjLQmbJDqxAhd2MGThHG",
        area: 945087,
        borders: [
            "bi",
            "cd",
            "ke",
            "mw",
            "mz",
            "rw",
            "ug",
            "zm"
        ]
    },

    th: {
        name: "Thailand",
        iso: "th",
        alt: "",
        continent: "asia",
        lat: 15.870032,
        long: 100.992541,
        uuid: "4gfBq2wqQdEji67aaNQ9vW",
        area: 513120,
        borders: [
            "mm",
            "kh",
            "la",
            "my"
        ]
    },

    tl: {
        name: "Timor-Leste",
        iso: "tl",
        alt: "",
        continent: "asia",
        lat: -8.874217,
        long: 125.727539,
        uuid: "3LGU8fvurGK9cy258q3fRT",
        area: 14874,
        borders: [
            "id"
        ]
    },

    tg: {
        name: "Togo",
        iso: "tg",
        alt: "",
        continent: "africa",
        lat: 8.619543,
        long: 0.824782,
        uuid: "kdBi8rrgmAWJ6dm9TuVxCV",
        area: 56785,
        borders: [
            "bj",
            "bf",
            "gh"
        ]
    },

    tk: {
        name: "Tokelau",
        iso: "tk",
        alt: "",
        continent: "oceania",
        lat: -8.967363,
        long: -171.855881,
        uuid: "3gBMCyXVLL7JUNsr4f27Dd",
        area: 12
    },

    to: {
        name: "Tonga",
        iso: "to",
        alt: "",
        continent: "oceania",
        lat: -21.178986,
        long: -175.198242,
        uuid: "3Fy6WdvarZ4TzS3D3KWhQs",
        area: 747
    },

    tt: {
        name: "Trinidad and Tobago",
        iso: "tt",
        alt: "TrinidadandTobago",
        continent: "namerica",
        lat: 10.691803,
        long: -61.222503,
        uuid: "336bWR3XuJRBJrdGKNVhQh",
        area: 5130
    },

    tn: {
        name: "Tunisia",
        iso: "tn",
        alt: "",
        continent: "africa",
        lat: 33.886917,
        long: 9.537499,
        uuid: "3zJAhtLQNZ3uXVALuZkqzD",
        area: 163610,
        borders: [
            "dz",
            "ly"
        ]
    },

    tr: {
        name: "Turkey",
        iso: "tr",
        alt: "",
        continent: "asia",
        lat: 38.963745,
        long: 35.243322,
        uuid: "3sbWCXcT6jJeEeDtkvDUkh",
        area: 783562,
        borders: [
            "am",
            "az",
            "bg",
            "ge",
            "gr",
            "ir",
            "iq",
            "sy"
        ]
    },

    tm: {
        name: "Turkmenistan",
        iso: "tm",
        alt: "",
        continent: "asia",
        lat: 38.969719,
        long: 59.556278,
        uuid: "MtKgzQvrhrEaPYWCFtELYB",
        area: 488100,
        borders: [
            "af",
            "ir",
            "kz",
            "uz"
        ]
    },

    tc: {
        name: "Turks and Caicos Islands",
        iso: "tc",
        alt: "TurksandCaicosIslands",
        continent: "namerica",
        lat: 21.694025,
        long: -71.797928,
        uuid: "a5ueSa28ejYiZWxpzapS2W",
        area: 948
    },

    tv: {
        name: "Tuvalu",
        iso: "tv",
        alt: "",
        continent: "oceania",
        lat: -7.109535,
        long: 177.64933,
        uuid: "DKBzg6Mb8sxEBPW4Q8cXY4",
        area: 26
    },

    ug: {
        name: "Uganda",
        iso: "ug",
        alt: "",
        continent: "africa",
        lat: 1.373333,
        long: 32.290275,
        uuid: "hRKpHN3MuMjFoxzPCsvszx",
        area: 241550,
        borders: [
            "cd",
            "ke",
            "rw",
            "ss",
            "tz"
        ]
    },

    ua: {
        name: "Ukraine",
        iso: "ua",
        alt: "",
        continent: "europe",
        lat: 48.379433,
        long: 31.16558,
        uuid: "Q8U9xgUZP67EQzQ6rf2YeX",
        area: 603500,
        borders: [
            "by",
            "hu",
            "md",
            "pl",
            "ro",
            "ru",
            "sk"
        ]
    },

    ae: {
        name: "United Arab Emirates",
        iso: "ae",
        alt: "UnitedArabEmirates",
        continent: "asia",
        lat: 23.424076,
        long: 53.847818,
        uuid: "dgmDNYQreebbxoXFcbDpbh",
        area: 83600,
        borders: [
            "om",
            "sa"
        ]
    },

    gb: {
        name: "United Kingdom",
        iso: "gb",
        alt: "UnitedKingdom, Great Britain, GreatBritain",
        continent: "europe",
        lat: 55.378051,
        long: -3.435973,
        uuid: "33i9mxPDJk6bbLHaqsqCTp",
        area: 242900,
        borders: [
            "ie"
        ]
    },

    us: {
        name: "United States",
        iso: "us",
        alt: "UnitedStates",
        continent: "namerica",
        lat: 37.09024,
        long: -95.712891,
        uuid: "cUqCTkaye4GcVKQZrWtQLQ",
        area: 9372610,
        borders: [
            "ca",
            "mx"
        ]
    },
    
    um: {
        name: "United States Minor Outlying Islands",
        iso: "um",
        alt: "UnitedStatesMinorOutlyingIslands",
        continent: "namerica",
        lat: 19.3,
        long: 166.633333,
        uuid: "",
        area: 34.2
    },

    vi: {
        name: "United States Virgin Islands",
        iso: "vi",
        alt: "UnitedStatesVirginIslands",
        continent: "namerica",
        lat: 18.335765,
        long: -64.896335,
        uuid: "3eiQ2XNk5tSVRs2vUxDFAs",
        area: 347
    },

    uy: {
        name: "Uruguay",
        iso: "uy",
        alt: "",
        continent: "samerica",
        lat: -32.522779,
        long: -55.765835,
        uuid: "3vGvnrKjzoX5bEMcK3ZdXJ",
        area: 181034,
        borders: [
            "ar",
            "br"
        ]
    },

    uz: {
        name: "Uzbekistan",
        iso: "uz",
        alt: "",
        continent: "asia",
        lat: 41.377491,
        long: 64.585262,
        uuid: "3hmBNPNDZNsa45sY5S2wex",
        area: 447400,
        borders: [
            "af",
            "kz",
            "kg",
            "tj",
            "tm"
        ]
    },

    vu: {
        name: "Vanuatu",
        iso: "vu",
        alt: "",
        continent: "oceania",
        lat: -15.376706,
        long: 166.959158,
        uuid: "3UxTru78DrcrqE8sr6amCd",
        area: 12189
    },

    va: {
        name: "Vatican City (Holy See)",
        iso: "va",
        alt: "VaticanCity(HolySee),Vatican City,Holy See,VaticanCity,HolySee",
        continent: "europe",
        lat: 41.902916,
        long: 12.453389,
        uuid: "3yjk7MUMETcwiqw4vFAMgz",
        area: 0.44,
        borders: [
            "it"
        ]
    },

    ve: {
        name: "Venezuela",
        iso: "ve",
        alt: "",
        continent: "samerica",
        lat: 6.42375,
        long: -66.58973,
        uuid: "ekCBJdogu5r7nZkzVHKzRW",
        area: 916445,
        borders: [
            "br",
            "co",
            "gy"
        ]
    },

    vn: {
        name: "Vietnam",
        iso: "vn",
        alt: "",
        continent: "asia",
        lat: 14.058324,
        long: 108.277199,
        uuid: "3C7mWrCxHKHxGNSqMsfqyA",
        area: 331212,
        borders: [
            "kh",
            "cn",
            "la"
        ]
    },

    gbwls: {
        name: "Wales",
        iso: "gb-wls",
        alt: "",
        continent: "europe",
        lat: 52.1307,
        long: -3.7837,
        uuid: "3Ly7UYTvkM5hnvTHiDbhGn",
        area: 20779,
        borders: [
            "gb-eng"
        ],
        capitalCity: "Cardiff",
        population: 3170000,
        currency: "Pound sterling",
        language: "English, Welsh",
        googleMaps: "https://goo.gl/maps/LWA7sSUEebTsQwQW6"
    },

    wf: {
        name: "Wallis and Futuna",
        iso: "wf",
        alt: "WallisandFutuna",
        continent: "oceania",
        lat: -13.768752,
        long: -177.156097,
        uuid: "CQpADUnBg4C2WJp9yZv8cs",
        area: 142
    },

    eh: {
        name: "Western Sahara",
        iso: "eh",
        alt: "WesternSahara",
        continent: "africa",
        lat: 24.215527,
        long: -12.885834,
        uuid: "3HdiGxK5gixxx92XXTDSgx",
        area: 266000,
        borders: [
            "dz",
            "mr",
            "ma"
        ]
    },

    ye: {
        name: "Yemen",
        iso: "ye",
        alt: "",
        continent: "asia",
        lat: 15.552727,
        long: 48.516388,
        uuid: "eCrn5kMQtGqxzWvvboWdjV",
        area: 527968,
        borders: [
            "om",
            "sa"
        ]
    },

    zm: {
        name: "Zambia",
        iso: "zm",
        alt: "",
        continent: "africa",
        lat: -13.133897,
        long: 27.849332,
        uuid: "3eCZfMWocmS2bhSfUHihox",
        area: 752612,
        borders: [
            "ao",
            "bw",
            "cd",
            "mw",
            "mz",
            "na",
            "tz",
            "zw"
        ]
    },

    zw: {
        name: "Zimbabwe",
        iso: "zw",
        alt: "",
        continent: "africa",
        lat: -19.015438,
        long: 29.154857,
        uuid: "3zCBgiNFEBeatn9B7kudQv",
        area: 390757,
        borders: [
            "bw",
            "mz",
            "za",
            "zm"
        ]
    },

    ax: {
        name: "Åland Islands",
        iso: "ax",
        alt: "Aland Islands,ÅlandIslands",
        continent: "europe",
        lat: 60.1785,
        long: 19.9156,
        uuid: "WGjfzkY36CqciS39oHNGAD",
        area: 1580
    }
}

export default COUNTRY_DATA;