import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DateTime } from 'luxon';

import usePersistentState from './hooks/usePersistentState';
import usePersistentDateState from './hooks/usePersistentDateState';
import useStats from './hooks/useStats';

import { AppContainer } from './containers';
import HelpModal from './modals/HelpModal';
import StatisticsModal from './modals/StatisticsModal';
import AchievementsModal from './modals/AchievementsModal';
import AchievementNotification from './modals/AchievementNotification';
import SettingsModal from './modals/SettingsModal';
import Anywheredle from './Anywheredle';
import { ACHIEVEMENT_IDS, GUESS_ACHIEVEMENTS } from './util/achievements';

const startDateString = '01/06/2022';
const dateString = DateTime.utc().toFormat('dd/MM/yyyy');
const previousDayDateString = DateTime.fromFormat(dateString, 'dd/MM/yyyy').minus({ days: 1 }).toFormat('dd/MM/yyyy');

function App() {
    const [showHelp, setShowHelp] = useState(false);
    const [showStats, setShowStats] = useState(false);
    const [showAchievements, setShowAchievements] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    
    const [darkTheme, setDarkTheme] = usePersistentState("darkTheme", matchMedia('(prefers-color-scheme: dark)').matches);
    const [descriptiveIcons, setDescriptiveIcons] = usePersistentState("descriptiveIcons", true);
    const [challenge, setChallenge] = usePersistentDateState("challenge", dateString);
    const challengeMode = useMemo(() => Object.values(challenge)[0], [challenge]);

    const [stats, updateStats] = useStats(dateString);

    const [achievements, setAchievements] = usePersistentState("achievements", { date: null, unlocked: [] });
    const [newAchievementsList, setNewAchievementsList] = useState([]);
    const [showAchievementNotification, setShowAchievementNotification] = useState(false);
    const [achievementNotificationList, setAchievementNotificationList] = useState([]);
    const [delayedAchievements, setDelayedAchievements] = useState([]);
    const achievementTimer = useRef(null);

    const updateAchievements = useCallback((currUnlockedAchievements, newUnlockedAchievements, notifTimeout) => {
        const difference = newUnlockedAchievements.filter(({ id }) => {
            return currUnlockedAchievements.length === 0 || !currUnlockedAchievements.some(achievement => achievement.id === id);
        });

        if (difference.length > 0) {
            setAchievements({ date: dateString, unlocked: newUnlockedAchievements });
            setNewAchievementsList(prev => [ ...prev, ...difference ]);

            achievementTimer.current = setTimeout(() => {
                setShowAchievementNotification(true);
                setAchievementNotificationList(difference);
            }, notifTimeout);
        }
    }, [setAchievements, setNewAchievementsList, setShowAchievementNotification, setAchievementNotificationList]);

    useEffect(() => {
        const lastCompleted = JSON.parse(localStorage.getItem("stats"))?.lastCompleted;
        let timer;
        
        if (!lastCompleted)
            timer = setTimeout(() => setShowHelp(true), 500);

        return () => {
            clearTimeout(timer);
            clearTimeout(achievementTimer?.current);
        }
    }, [setShowHelp]);

    useEffect(() => {
        function addAchievementsGameEnd() {
            const [currUnlockedAchievements, newUnlockedAchievements] = getAchievementsInfo();
    
            const addAchievement = id => {
                if (!newUnlockedAchievements.some(achievement => achievement.id === id))
                    newUnlockedAchievements.push({ date: DateTime.now().toFormat('dd/MM/yyyy'), id: id });
            };
            
            delayedAchievements.forEach(id => addAchievement(id));
    
            const { completed, won, currStreak, continentCount, distribution } = stats;//JSON.parse(localStorage.getItem("stats"));
    
            Object.keys(distribution).forEach(key => {
                if (distribution[key] > 0)
                    addAchievement(GUESS_ACHIEVEMENTS[key]);
            });

            if (completed >= 50)
                addAchievement(ACHIEVEMENT_IDS.Perseverant);
    
            const guessedEveryContinent = Object.keys(continentCount).map(key => {
                return key === 'antarctica' || continentCount[key] > 0;
            }).every(value => value === true);
    
            if (guessedEveryContinent)
                addAchievement(ACHIEVEMENT_IDS.Continental);
    
            if (won >= 50)
                addAchievement(ACHIEVEMENT_IDS.Globetrotter);
    
            if (continentCount['antarctica'] > 0)
                addAchievement(ACHIEVEMENT_IDS.Explorer);
    
            if (currStreak === 30) {
                addAchievement(ACHIEVEMENT_IDS.Unstoppable);
            } else if (currStreak === 15) {
                addAchievement(ACHIEVEMENT_IDS.HotStreak);
            } else if (currStreak === 5) {
                addAchievement(ACHIEVEMENT_IDS.HeatingUp);
            }
            
            updateAchievements(currUnlockedAchievements, newUnlockedAchievements, 1750);
        }
        
        if (stats.lastCompleted === dateString && achievements.date !== dateString)
            addAchievementsGameEnd();
    }, [stats, achievements.date, delayedAchievements, updateAchievements]);

    useEffect(() => {
        if (showAchievements)
            setShowAchievementNotification(false);
    }, [showAchievements]);

    function handleChallengeChange(sliderValue) {
        const previousChallengeValues = localStorage.getItem("challenge");
        setChallenge({ ...JSON.parse(previousChallengeValues), [dateString]: sliderValue });
    }

    function getAchievementsInfo() {
        const currUnlockedAchievements = JSON.parse(localStorage.getItem("achievements"))?.unlocked ?? [];
        const newUnlockedAchievements = [ ...currUnlockedAchievements ];

        return [currUnlockedAchievements, newUnlockedAchievements];
    }

    const addAchievementFromEvent = useCallback((achievement) => {
        const [currUnlockedAchievements, newUnlockedAchievements] = getAchievementsInfo();

        const addAchievement = id => {
            if (!newUnlockedAchievements.some(achievement => achievement.id === id))
                newUnlockedAchievements.push({ date: DateTime.now().toFormat('dd/MM/yyyy'), id: id });
        };

        if (achievement.delay) {
            if (!currUnlockedAchievements.includes(achievement.id) && !delayedAchievements.includes(achievement.id)) 
                setDelayedAchievements(prev => [ ...prev, achievement.id ]);

            return;
        }

        addAchievement(achievement.id);

        updateAchievements(currUnlockedAchievements, newUnlockedAchievements, 1000);
    }, [delayedAchievements, setDelayedAchievements, updateAchievements])

    return (
        <AppContainer darkTheme={darkTheme}>
            <AchievementNotification 
                showNotification={showAchievementNotification && newAchievementsList.length > 0}
                achievementNotificationList={achievementNotificationList}
                setShowAchievementNotification={setShowAchievementNotification}
                setShowAchievements={setShowAchievements} />
            <StatisticsModal 
                showModal={showStats} 
                stats={stats} 
                startDateString={startDateString}
                dateString={dateString}
                previousDayDateString={previousDayDateString}
                setShowStats={setShowStats} />
            <HelpModal showModal={showHelp} setShowHelp={setShowHelp} descriptiveIcons={descriptiveIcons} />
            <SettingsModal
                showModal={showSettings}
                dateString={dateString}
                darkTheme={darkTheme}
                setDarkTheme={setDarkTheme}
                descriptiveIcons={descriptiveIcons}
                setDescriptiveIcons={setDescriptiveIcons}
                challenge={challenge}
                onChallengeChange={handleChallengeChange}
                setShowSettings={setShowSettings} />
            <AchievementsModal 
                showModal={showAchievements}
                achievements={achievements}
                newAchievementsList={newAchievementsList}
                setShowAchievements={setShowAchievements}
                setNewAchievementsList={setNewAchievementsList} />
            <Anywheredle
                startDateString={startDateString}
                dateString={dateString}
                previousDayDateString={previousDayDateString}
                updateStats={updateStats}
                challengeMode={challengeMode}
                setShowHelp={setShowHelp}
                setShowStats={setShowStats}
                setShowAchievements={setShowAchievements}
                setShowSettings={setShowSettings}
                darkTheme={darkTheme}
                descriptiveIcons={descriptiveIcons}
                addAchievementFromEvent={addAchievementFromEvent}
                ariaHidden={showHelp || showStats || showSettings} />
        </AppContainer>
    );
}

export default App;