import { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { EVALS } from '../util/evals';

function getSavedStats(dateString) {
    const stats = JSON.parse(localStorage.getItem("stats"));

    if (stats?.lastCompleted) {
        const lastCompletedDate = DateTime.fromFormat(stats.lastCompleted, 'dd/MM/yyyy');
        const presentDate = DateTime.fromFormat(dateString, 'dd/MM/yyyy');

        if (lastCompletedDate.plus({ days: 1 }) < presentDate)
            stats.currStreak = 0;
    }
    
    return stats ? stats : { 
        lastCompleted: null,
        completed: 0, 
        won: 0, 
        currStreak: 0, 
        bestStreak: 0, 
        continentCount: { africa: 0, antarctica: 0, asia: 0, europe: 0, namerica: 0, oceania: 0, samerica: 0 },
        distribution: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }
    };
}

function useStats(dateString) {
    const [stats, setStats] = useState(getSavedStats(dateString));

    useEffect(() => {
        localStorage.setItem("stats", JSON.stringify(stats));
    }, [stats]);

    const updateStats = useCallback((guesses, dateString, targetCountry) => {
        if (stats.lastCompleted) {
            const lastCompletedDate = DateTime.fromFormat(stats.lastCompleted, 'dd/MM/yyyy');
            const presentDate = DateTime.fromFormat(dateString, 'dd/MM/yyyy');

            if (stats.lastCompleted === dateString || presentDate < lastCompletedDate)
                return;
        }

        const newStats = { ...stats };
        newStats.completed++;
        
        const win = guesses[dateString].some(guess => guess?.eval === EVALS.Correct);

        if (win) {
            newStats.won++;
            newStats.currStreak++;

            if (newStats.currStreak > newStats.bestStreak)
                newStats.bestStreak = newStats.currStreak;
            
            const continent = targetCountry.continent;
            newStats.continentCount[continent]++;

            let numGuesses = guesses[dateString].indexOf(null);
            if (numGuesses === -1)
                numGuesses = 6;
            
            newStats.distribution[numGuesses]++;
        } else {
            newStats.currStreak = 0;
        }
        
        newStats.lastCompleted = dateString;

        setStats(newStats);
    }, [stats]);

    return [stats, updateStats];
}

export default useStats;