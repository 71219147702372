import { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { CSSTransition } from 'react-transition-group';

import COUNTRY_DATA from '../util/countries';
import { ACHIEVEMENT_IDS } from '../util/achievements';

const keys = [
    'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p',
    'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l',
    'z', 'x', 'c', 'v', 'b', 'n', 'm', ' '
]

function padNumber(number, padBoundary = 10, pad = '0') {
    return number < padBoundary ? `${pad}${number}` : number;
}

function TextInputContainer({ addAchievementFromEvent, ...props }) {
    const [textInput, setTextInput] = useState('');
    const [countryListIsOpen, setCountryListIsOpen] = useState(true);
    const [countryList, setCountryList] = useState(null);
    const [countryNotFound, setCountryNotFound] = useState(false);

    const textInputRef = useRef(null);
    const countryListRef = useRef(null);
    const timer = useRef(null);
    const notifRef = useRef(null);

    useEffect(() => {
        function handleBlur(e) {
            if(countryListRef.current && countryListIsOpen && !countryListRef.current.contains(e.target))
                setCountryListIsOpen(false);
        }

        function handleKeyDown(e) {
            if ((keys.includes(e.key.toLowerCase()) || e.key === 'Backspace') && !props.disabled) {
                textInputRef?.current.focus();
                return;
            }
        }

        document.addEventListener("mousedown", handleBlur);
        document.addEventListener("keydown", handleKeyDown);

        return () => { 
            document.removeEventListener("mousedown", handleBlur);
            document.removeEventListener("keydown", handleKeyDown);
        }
    }, [countryListIsOpen, props.disabled]);

    useEffect(() => {
        let timer;
        let interval;

        if (props.disabled) {
            timer = setTimeout(() => {
                const nextDayUTCDate = DateTime.fromObject({hour: 0, minute: 0}, {zone: 'UTC'}).plus({ days: 1 });
                let currUTCDate = DateTime.utc();
                let diff, hours, minutes, seconds;

                interval = setInterval(() => {
                    diff = nextDayUTCDate.diff(currUTCDate, ['hours', 'minutes', 'seconds']);

                    if (diff.seconds <= 0) {
                        setTextInput('REFRESH PAGE');
                        clearInterval(interval);
                        
                        addAchievementFromEvent({ id: ACHIEVEMENT_IDS.EarlyBird, delay: false });
                    } else {
                        ({ hours, minutes, seconds } = diff.values);
                        setTextInput(`New country in ${padNumber(hours)}h ${padNumber(minutes)}m ${padNumber(Math.round(seconds))}s`);
                        currUTCDate = DateTime.utc();
                    }
                }, 1000);
            }, 5000);
        }

        return () => {
            clearTimeout(timer);
            clearInterval(interval);
        }
    }, [props.disabled, addAchievementFromEvent]);

    function handleCountryListBlur() {
        timer.current = setTimeout(() => {
            setCountryListIsOpen(false);
        });
    }

    function handleCountryListFocus() {
        clearTimeout(timer.current);
    }

    function handleCountryClick(country) {
        setTextInput('');
        setCountryListIsOpen(false);
        setCountryNotFound(false);

        props.handleNewGuess(country);
    }
    
    function handleInput(e) {
        setCountryNotFound(false);

        const input = e.target.value.toLowerCase();
        setTextInput(input.toUpperCase());

        if(input === '') {
            const allCountries = Object.values(COUNTRY_DATA);
            setCountryList(allCountries);
            setCountryListIsOpen(true);
            return;
        }

        const matchingCountries = [];

        Object.keys(COUNTRY_DATA).forEach(iso => {
            const country = COUNTRY_DATA[iso];
            const countryName = country.name.toLowerCase();
            const countryAlt = country.alt.split(',').map(alt => alt.toLowerCase());

            if (countryName.startsWith(input) || countryAlt.some(alt => alt.startsWith(input)))
                matchingCountries.push(country);
        });
        setCountryList(matchingCountries);
        setCountryListIsOpen(true);

        textInputRef.current.setAttribute("aria-invalid", "false");
    }

    function handleKeyPress(e) {
        if(e.key === 'Enter') {
            const text = textInput;

            if (text === '') 
                return;

            const matchingIso = Object.keys(COUNTRY_DATA).find(iso => {
                const countryName = COUNTRY_DATA[iso].name.toLowerCase();
                const countryAlt = COUNTRY_DATA[iso].alt.split(',').map(alt => alt.toLowerCase());
                
                return text === countryName || countryAlt.includes(text);
            });

            if (matchingIso || countryList.length === 1) {
                setTextInput('');
                setCountryListIsOpen(false);

                const guess = matchingIso ? COUNTRY_DATA[matchingIso] : countryList[0];
                props.handleNewGuess(guess);
            } else {
                textInputRef.current.setAttribute("aria-invalid", "true");
                setCountryNotFound(true);
            }
        }
    }
    
    return (
        <div className="text-input-container">
            <input
                ref={textInputRef}
                value={textInput}
                type="text"
                className="text-input" 
                placeholder="Your Guess Here..." 
                // autoFocus={true}
                disabled={props.disabled}
                onChange={handleInput}
                onFocus={handleInput}
                onKeyPress={handleKeyPress}
                aria-required="true"
                aria-invalid="false"
                aria-describedby="country-not-found" />
            {/* { textInput !== '' && !props.disabled && 
                <button className="enter-button" onClick={handleEnterPress}>Enter</button> } */}
            { countryListIsOpen && 
                <div 
                    ref={countryListRef}
                    className="country-list-container" 
                    onFocus={handleCountryListFocus} 
                    onBlur={handleCountryListBlur}
                    role="list"
                    title="matching countries"
                    >
                    { countryList?.map(country => {
                        return ( 
                            <button key={country.uuid} country={country} className="country" onClick={() => handleCountryClick(country)}>
                                {country.name}
                            </button>
                        );
                      }) }
                </div> }
            <CSSTransition
                nodeRef={notifRef}
                in={countryNotFound}
                timeout={400}
                classNames="country-not-found-transition"
                appear
                unmountOnExit
            >  
                <div 
                    ref={notifRef}
                    id="country-not-found" 
                    className="country-not-found" 
                    aria-hidden="false"
                >
                    Country Not Found
                </div>
            </CSSTransition>
        </div>
    );
}

export default TextInputContainer;