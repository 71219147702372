import Locked from '../icons/achievements/locked.svg';
import PS from '../icons/achievements/PS.svg';
import CL from '../icons/achievements/CL.svg';
import TR from '../icons/achievements/TR.svg';
import L4 from '../icons/achievements/L4.svg';
import FiveAlive from '../icons/achievements/5A.svg';
import CC from '../icons/achievements/CC.svg';
import SC from '../icons/achievements/SC.svg';
import EB from '../icons/achievements/EB.svg';
import CN from '../icons/achievements/CN.svg';
import PE from '../icons/achievements/PE.svg';
import GT from '../icons/achievements/GT.svg';
import EX from '../icons/achievements/EX.svg';
import HU from '../icons/achievements/HU.svg';
import HS from '../icons/achievements/HS.svg';
import UN from '../icons/achievements/UN.svg';

export const ACHIEVEMENT_IDS = {
    Psychic: 'PS',
    ClockOffEarly: 'CL',
    Triumphant: 'TR',
    LuckyNo4: 'L4',
    FiveAlive: '5A',
    CloseCall: 'CC',
    Scholar: 'SC',
    EarlyBird: 'EB',
    Continental: 'CN',
    Perseverant: 'PE',
    Globetrotter: 'GT',
    Explorer: 'EX',
    HeatingUp: 'HU',
    HotStreak: 'HS',
    Unstoppable: 'UN'
}

export const ACHIEVEMENT_NAMES = {
    [ACHIEVEMENT_IDS.Psychic]: 'Psychic',
    [ACHIEVEMENT_IDS.ClockOffEarly]: 'Clock Off Early',
    [ACHIEVEMENT_IDS.Triumphant]: 'Triumphant',
    [ACHIEVEMENT_IDS.LuckyNo4]: 'Lucky no. 4',
    [ACHIEVEMENT_IDS.FiveAlive]: '5 Alive',
    [ACHIEVEMENT_IDS.CloseCall]: 'Close Call',
    [ACHIEVEMENT_IDS.Scholar]: 'Scholar',
    [ACHIEVEMENT_IDS.EarlyBird]: 'Early Bird',
    [ACHIEVEMENT_IDS.Continental]: 'Continental',
    [ACHIEVEMENT_IDS.Perseverant]: 'Perseverant',
    [ACHIEVEMENT_IDS.Globetrotter]: 'Globetrotter',
    [ACHIEVEMENT_IDS.Explorer]: 'Explorer',
    [ACHIEVEMENT_IDS.HeatingUp]: 'Heating Up',
    [ACHIEVEMENT_IDS.HotStreak]: 'Hot Streak',
    [ACHIEVEMENT_IDS.Unstoppable]: 'Unstoppable'
}

export const ACHIEVEMENT_ICONS = {
    Locked: Locked,
    [ACHIEVEMENT_IDS.Psychic]: PS,
    [ACHIEVEMENT_IDS.ClockOffEarly]: CL,
    [ACHIEVEMENT_IDS.Triumphant]: TR,
    [ACHIEVEMENT_IDS.LuckyNo4]: L4,
    [ACHIEVEMENT_IDS.FiveAlive]: FiveAlive,
    [ACHIEVEMENT_IDS.CloseCall]: CC,
    [ACHIEVEMENT_IDS.Scholar]: SC,
    [ACHIEVEMENT_IDS.EarlyBird]: EB,
    [ACHIEVEMENT_IDS.Continental]: CN,
    [ACHIEVEMENT_IDS.Perseverant]: PE,
    [ACHIEVEMENT_IDS.Globetrotter]: GT,
    [ACHIEVEMENT_IDS.Explorer]: EX,
    [ACHIEVEMENT_IDS.HeatingUp]: HU,
    [ACHIEVEMENT_IDS.HotStreak]: HS,
    [ACHIEVEMENT_IDS.Unstoppable]: UN
}

export const GUESS_ACHIEVEMENTS = {
    1: ACHIEVEMENT_IDS.Psychic,
    2: ACHIEVEMENT_IDS.ClockOffEarly,
    3: ACHIEVEMENT_IDS.Triumphant,
    4: ACHIEVEMENT_IDS.LuckyNo4,
    5: ACHIEVEMENT_IDS.FiveAlive,
    6: ACHIEVEMENT_IDS.CloseCall
}