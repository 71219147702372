import Modal from './Modal';

function SymbolModal(props) {
    return (
        <Modal 
            showModal={props.showModal}
            rootClass="symbol-modal-root"
            modalOverlayClass="modal-overlay symbol-modal-overlay"
            modalClass="small-modal symbol-modal"
            modalHeaderClass="symbol-modal-header"
            modalTitleClass="symbol-modal-title"
            modalContentClass="symbol-modal-content"
            modalContentStyle={props.modalContentStyle}
            title={props.title}
            subtitle={props.subtitle}
            setShowModal={props.setShowSymbolModal}
            multiClose={true} />
    );
}

export default SymbolModal;