import Modal from './Modal';
import Achievement from '../components/Achievement';
import { ACHIEVEMENT_IDS, ACHIEVEMENT_NAMES } from '../util/achievements';

function AchievementsModal(props) {
    return (
        <Modal 
            showModal={props.showModal}
            modalOverlayClass="modal-overlay"
            modalClass="modal"
            modalHeaderClass="modal-header"
            modalTitleClass="modal-title"
            modalContentClass="achievements-modal-content"
            title="ACHIEVEMENTS" 
            setShowModal={props.setShowAchievements}
            multiClose={false}
        >
            <section>
                <div className="achievements-container">
                    { Object.values(ACHIEVEMENT_IDS).map(id => {
                        return (<Achievement 
                                    key={id}
                                    id={id}
                                    achievementInfo={props.achievements.unlocked.find(achievement => achievement.id === id)}
                                    unlocked={props.achievements.unlocked.some(achievement => achievement.id === id)} 
                                    title={ACHIEVEMENT_NAMES[id]} 
                                    newAchievement={props.newAchievementsList.some(achievement => achievement.id === id)} />);
                    }) }
                </div>
            </section>
        </Modal>
    );
}

export default AchievementsModal;