import { useEffect, useState } from 'react';

function BorderCell(props) {
    const [cellBackStyle, setCellBackStyle] = useState(null);

    useEffect(() => {
        if (props.reveal) {
            setCellBackStyle({
                backgroundImage: `url(${process.env.PUBLIC_URL}/borders/${props.targetCountry.uuid}_cell_${props.order}.svg)`,
                backgroundPosition: props.bgPos
            });
        } else {
            setCellBackStyle(null);
        }

    }, [props.reveal, props.targetCountry.iso, props.targetCountry.uuid, props.order, props.bgPos]);

    return (
        <div className={props.reveal ? "border-cell-container border-cell-reveal" : "border-cell-container"} >
            <div className="border-cell-front" />
            { props.reveal && <div className="border-cell-back" style={cellBackStyle} /> }
        </div>
    );
}

export default BorderCell;