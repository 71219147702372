import { DateTime } from 'luxon';
import Modal from './Modal';
import { ACHIEVEMENT_IDS, ACHIEVEMENT_NAMES } from '../util/achievements';

const ACHIEVEMENT_DESCRIPTIONS = {
    [ACHIEVEMENT_IDS.Psychic]: 'You found the daily country in 1 try!',
    [ACHIEVEMENT_IDS.ClockOffEarly]: 'You found the daily country in 2 tries!',
    [ACHIEVEMENT_IDS.Triumphant]: 'You found the daily country in 3 tries!',
    [ACHIEVEMENT_IDS.LuckyNo4]: 'You found the daily country in 4 tries!',
    [ACHIEVEMENT_IDS.FiveAlive]: 'You found the daily country in 5 tries!',
    [ACHIEVEMENT_IDS.CloseCall]: 'You found the daily country in 6 tries!',
    [ACHIEVEMENT_IDS.Scholar]: 'You took a closer look at a national symbol!',
    [ACHIEVEMENT_IDS.EarlyBird]: 'You watched the countdown timer reach 0!',
    [ACHIEVEMENT_IDS.Continental]: 'You correctly guessed a country from (almost) all continents!',
    [ACHIEVEMENT_IDS.Perseverant]: 'You completed 50 games!',
    [ACHIEVEMENT_IDS.Globetrotter]: 'You correctly guessed 50 countries!',
    [ACHIEVEMENT_IDS.Explorer]: 'Uncharted! You found Antarctica.',
    [ACHIEVEMENT_IDS.HeatingUp]: 'On a roll! You hit a 5 day streak.',
    [ACHIEVEMENT_IDS.HotStreak]: 'Over 2 weeks! You found the daily country 15 days in a row.',
    [ACHIEVEMENT_IDS.Unstoppable]: 'What are they feeding you! You correctly guessed the daily country for 30 days straight.'
}

function AchievementInfoModal(props) {
    return (
        <Modal 
            showModal={props.showAchievementInfo}
            rootClass="achievement-info-modal-root"
            modalOverlayClass="modal-overlay small-modal-overlay"
            modalClass="small-modal achievement-info-modal"
            modalHeaderClass="modal-header achievement-info-modal-header"
            modalTitleClass="modal-title"
            modalContentClass="achievement-info-modal-content"
            title={props.title} 
            setShowModal={props.setShowAchievementInfo}
            multiClose={true}
        >
            <section>
                <div className="achievement-info-container">
                    <img src={props.icon} className="achievement-info-icon" alt={ACHIEVEMENT_NAMES[props.id]} />
                    <div className="achievement-info-description">
                        <p style={{ color: props.unlocked ? 'var(--text-primary)' : 'var(--app-clr-4)' }}>{ACHIEVEMENT_DESCRIPTIONS[props.id]}</p>
                    </div>
                    { props.unlocked && 
                        <p className="achievement-info-unlock-date">
                            {`Unlocked on ${DateTime.fromFormat(props.date, 'dd/MM/yyyy').toLocaleString(DateTime.DATE_MED)}`}
                        </p> }
                </div>
            </section>
        </Modal>
    );
}

export default AchievementInfoModal;