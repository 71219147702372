import { useMemo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import ResultInfo from '../components/ResultInfo';
import { CONTINENTS } from '../util/evals';

import { ReactComponent as Exit } from '../icons/exit.svg';
import { ReactComponent as Continent } from '../icons/result_modal_continent.svg';
import { ReactComponent as Population } from '../icons/result_modal_population.svg';
import { ReactComponent as Capital } from '../icons/result_modal_capital.svg';
import { ReactComponent as Language } from '../icons/result_modal_language.svg';
import { ReactComponent as Currency } from '../icons/result_modal_currency.svg';
import { ReactComponent as Area } from '../icons/result_modal_area.svg';
import { ReactComponent as Map } from '../icons/result_modal_map.svg';
// import { ReactComponent as PinIcon } from '../icons/pin.svg';
import pinIcon from '../icons/pin.svg';

function ResultModal(props) {
    const modalRef = useRef(null);
    
    const info = useMemo(() => {
        return { ...props.targetCountryInfo };
    }, [JSON.stringify(props.targetCountryInfo)]);
    
    return (
        <CSSTransition
            nodeRef={modalRef}
            in={props.showModal}
            timeout={250}
            classNames="result-modal-transition"
            unmountOnExit
        >
            <div ref={modalRef} className="small-modal result-modal">
                <header className="result-modal-header">
                    <div className="result-modal-title">
                        {info.name}
                    </div>
                    <button className="modal-exit-button" onClick={props.onCloseModal} aria-label="close modal">
                        <span role="img"><Exit className="modal-exit-icon" /></span>
                    </button>
                </header>
                <div className="result-modal-content">
                    <ResultInfo title="Continent" value={CONTINENTS[info.continent]} icon={<Continent />} />
                    <ResultInfo title="Population" value={info.population} icon={<Population />} />
                    <ResultInfo title="Capital" value={info.capitalCity} icon={<Capital />} />
                    <ResultInfo title="Language" value={info.language} icon={<Language />} />
                    <ResultInfo title="Currency" value={info.currency} icon={<Currency />} />
                    <ResultInfo title="Area" value={info.area} icon={<Area />} />
                    <ResultInfo 
                        title="Map"
                        value={
                            <a href={info.googleMaps} target="_blank" rel="noopener noreferrer">
                                {/* <PinIcon className="result-info-pin" /> */}
                                <img src={pinIcon} className="result-info-pin" alt="location pin icon" />
                            </a>} 
                        icon={<Map />} />
                    <a 
                        href={`https://en.wikipedia.org/wiki/${info.name}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="result-info-wiki"
                    >
                        Learn more...
                    </a>
                </div>
            </div>
        </CSSTransition>
    );
}

export default ResultModal;