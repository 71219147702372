import { ReactComponent as HelpButton } from '../icons/help.svg';
import { ReactComponent as StatsButton } from '../icons/stats.svg';
import { ReactComponent as AchievementsButton } from '../icons/achievements.svg';
import { ReactComponent as SettingsButton } from '../icons/settings.svg';

function PageHeader(props) {
    return (
        <header className="page-header" aria-hidden={props.ariaHidden}>
            <div className="page-header-content-container">
                <div className="menu-container left">
                    <button onClick={() => props.setShowHelp(true)} aria-label="help">
                        <HelpButton />
                    </button>
                    <button onClick={() => props.setShowStats(true)} aria-label="statistics">
                        <StatsButton />
                    </button>
                </div>
                <div className="app-title" role="heading" aria-level="1"><span className="app-title-highlight">Anywhere</span>dle</div>
                <div className="menu-container right">
                    <button onClick={() => props.setShowAchievements(true)} aria-label="achievements">
                        <AchievementsButton />
                    </button>
                    <button onClick={() => props.setShowSettings(true)} aria-label="settings">
                        <SettingsButton />
                    </button>
                </div>
            </div>
        </header>
    );
}

export default PageHeader;