function SettingsOption(props) {
    return (
        <div className="settings-option">
            <div className="settings-option-description">
                <div className="settings-option-description-title">{props.title}</div>
                <div className="settings-option-description-subtitle">{props.subtitle}</div>
            </div>
            {props.children}
        </div>
    );
}

export default SettingsOption;