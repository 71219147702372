function SymbolContainer(props) {
    return (
        <div data-type={props.type} className="symbol-flippable-container" role="img" aria-label={props.ariaLabel}>
            <div className={props.reveal ? "symbol-flippable-container-inner symbol-flip-reveal" : "symbol-flippable-container-inner"}>
                {props.children}
            </div>
        </div>
    );
}

export default SymbolContainer;