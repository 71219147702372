import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

function getSavedGuesses(dateString) {
    let guesses = localStorage.getItem("guesses");

    const presentDate = DateTime.fromFormat(dateString, 'dd/MM/yyyy');
    const previousDayDateString = presentDate.minus({ days: 1 }).toFormat('dd/MM/yyyy');

    if (!guesses) {
        guesses = { [previousDayDateString]: Array(6).fill(null), [dateString]: Array(6).fill(null) };
    } else {
        guesses = JSON.parse(guesses);
        const [beforeLatestDayPlayed, latestDayPlayed] = Object.keys(guesses);

        if (dateString === beforeLatestDayPlayed) {
            guesses = { [previousDayDateString]: Array(6).fill(null), [dateString]: guesses[beforeLatestDayPlayed] };
        } else if (dateString !== latestDayPlayed) {
            delete guesses[beforeLatestDayPlayed];

            if (latestDayPlayed === previousDayDateString) {
                guesses = { ...guesses, [dateString]: Array(6).fill(null) };
            } else {
                guesses = { [previousDayDateString]: Array(6).fill(null), [dateString]: Array(6).fill(null) };
            }
        }
    }

    return guesses;
}

function useGuess(dateString) {
    const [guesses, setGuesses] = useState(getSavedGuesses(dateString));

    useEffect(() => {
        localStorage.setItem("guesses", JSON.stringify(guesses));
    }, [guesses]);
    
    function updateGuesses(newGuesses) {
        const guessHistory = { ...guesses, [dateString]: newGuesses };
        setGuesses(guessHistory);
    }

    return [guesses, updateGuesses];
}

export default useGuess;