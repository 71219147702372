function ResultInfo(props) {
    return (
        <div className="result-info">
            <div className="result-info-icon">
                {props.icon}
            </div>
            <h4 className="result-info-title">{props.title}</h4>
            <div className="result-info-value">{props.value}{props.title === 'Area' && <sup>2</sup>}</div>
        </div>
    );
}

export default ResultInfo;