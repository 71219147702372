import { useState } from 'react';
import { DateTime } from 'luxon';

import Modal from './Modal';
import SettingsOption from '../components/SettingsOption';
import Slider from '../components/Slider';
import CopyButton from '../components/CopyButton';
import Collapsible from '../components/Collapsible';

const localTimeAtMidnightUTC = DateTime.fromObject({hour: 0, minute: 0}, {zone: 'UTC'}).toLocal().toFormat('HH:mm');

function SettingsModal(props) {
    const [collapsibleOpen, setCollapsibleOpen] = useState(null);

    function handleCollapsibleClick(collapsible) {
        if (collapsibleOpen === collapsible) {
            setCollapsibleOpen(null);
        } else {
            setCollapsibleOpen(collapsible);
        }
    }
    
    return (
        <Modal 
            showModal={props.showModal}
            modalOverlayClass="modal-overlay"
            modalClass="modal"
            modalHeaderClass="modal-header"
            modalTitleClass="modal-title"
            modalContentClass="modal-content"
            title="SETTINGS" 
            setShowModal={(value) => { 
                setCollapsibleOpen(null);
                props.setShowSettings(value);
            }}
            multiClose={false}
        >
            <section>
                <div className="settings-content">
                    <SettingsOption title="Dark Theme" subtitle="Change the light level">
                        <Slider 
                            checked={props.darkTheme} 
                            onChange={props.setDarkTheme} 
                            ariaLabel="dark theme" />
                    </SettingsOption>
                    <SettingsOption title="Descriptive Icons" subtitle="Show descriptive guess evaluation icons">
                        <Slider 
                            checked={props.descriptiveIcons} 
                            onChange={props.setDescriptiveIcons}
                            ariaLabel="descriptive icons" />
                    </SettingsOption>
                    <SettingsOption title="Challenge*" subtitle="Hide the emblem and flag">
                        <Slider 
                            checked={props.challenge[props.dateString]} 
                            onChange={props.onChallengeChange}
                            ariaLabel="challenge" />
                    </SettingsOption>
                    <SettingsOption title="Contact" subtitle="Issues or feedback">
                        <CopyButton 
                            className="contact-button" 
                            content={() => "anywheredlegame@gmail.com"}
                            notification="Copied"
                        >
                            Copy Email
                        </CopyButton>
                    </SettingsOption>
                </div>
                <p>*If checked or unchecked, the challenge option will apply from tomorrow.</p>
            </section>
            <div className="modal-separator"></div>
            <section>
                <div className="settings-content">
                    <Collapsible title="Which countries are included?" open={collapsibleOpen === 0} setCollapsibleOpen={() => handleCollapsibleClick(0)}>
                        All countries listed in the <a href="https://www.iso.org/obp/ui/#search/code/" target="_blank" rel="noopener noreferrer">ISO 3166-1</a> standard, including Kosovo and the constituent countries of the United Kingdom: England, Scotland, Wales, and Northern Ireland. This means 254 countries in total.
                    </Collapsible>
                    <Collapsible title="How is the daily country picked?" open={collapsibleOpen === 1} setCollapsibleOpen={() => handleCollapsibleClick(1)}>
                        At midnight Coordinated Universal Time ({localTimeAtMidnightUTC} local time), a new country is randomly chosen from the list of eligible countries. Once a country is picked, it cannot be chosen again for at least 60 days.
                    </Collapsible>
                    <Collapsible title="Where is the country data sourced from?" open={collapsibleOpen === 2} setCollapsibleOpen={() => handleCollapsibleClick(2)}>
                        Almost all border images are provided by <a href="https://twitter.com/maazarin" target="_blank" rel="noopener noreferrer">@maazarin</a> and are available at <a href="https://github.com/djaiss/mapsicon" target="_blank" rel="noopener noreferrer">this</a> repository. Other country information is pulled from the <a href="https://restcountries.com/" target="_blank" rel="noopener noreferrer">REST Countries API.</a>
                    </Collapsible>
                </div>
            </section>
        </Modal>
    );
}

export default SettingsModal;