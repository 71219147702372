import { useMemo } from "react";

function DistributionBar(props) {
    const barStyle = useMemo(() => {
        return props.modalGuess !== 0 ? { flexGrow: props.value / props.modalGuess } : null;
    }, [props.value, props.modalGuess]);

    return (
        <div className="distribution-bar-container">
            <div className="distribution-bar-label">{props.label}</div>
            <div className="distribution-bar" style={barStyle}>{props.value}</div>
        </div>
    );
}

export default DistributionBar;