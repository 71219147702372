import { ReactComponent as Expand } from '../icons/expand.svg';
import { ReactComponent as Collapse } from '../icons/collapse.svg';

function Collapsible(props) {
    return (
        <div className="collapsible">
            <button 
                className="collapsible-button" 
                onClick={props.setCollapsibleOpen}
            >
                {props.title}
                {props.open ? <Collapse /> : <Expand />}
            </button>
            { props.open && 
                <div className="collapsible-content">
                    <p>
                        {props.children}
                    </p>
                </div> }
        </div>
    );
}

export default Collapsible;