import { cloneElement, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { CSSTransition } from 'react-transition-group';

import Modal from './Modal';
import Guess from '../components/Guess';
import {
    EVALS,
    EVAL_SYMBOLS_COMPONENT,
    AREA_EVALS,
    AREA_EVAL_SYMBOLS,
    DIRECTIONS,
    DIRECTION_SYMBOLS } from '../util/evals';

import { ReactComponent as CarouselPrev } from '../icons/carousel_prev.svg';
import { ReactComponent as CarouselNext } from '../icons/carousel_next.svg';
import { ReactComponent as ActiveCarouselMarker } from '../icons/carousel_marker_active.svg';
import { ReactComponent as InactiveCarouselMarker } from '../icons/carousel_marker_inactive.svg';
import { ReactComponent as BoundaryIcon } from '../icons/boundary.svg';
import { ReactComponent as COAIcon } from '../icons/coa.svg';
import { ReactComponent as FlagIcon } from '../icons/flag.svg';

const carouselItems = [
    { eval: EVALS.Incorrect, dir: DIRECTIONS.E, areaEval: AREA_EVALS.Smaller, countryName: 'Argentina' },
    { eval: EVALS.Continent, dir: DIRECTIONS.S, areaEval: AREA_EVALS.Larger, countryName: 'Zambia' },
    { eval: EVALS.Border, dir: DIRECTIONS.SE, areaEval: AREA_EVALS.Larger, countryName: 'Namibia' },
    { eval: EVALS.Correct, dir: DIRECTIONS.N, areaEval: AREA_EVALS.Larger, countryName: 'South Africa' },
];

const carouselEvalItems = [
    { src: [EVAL_SYMBOLS_COMPONENT[EVALS.Incorrect], DIRECTION_SYMBOLS[DIRECTIONS.E], AREA_EVAL_SYMBOLS[AREA_EVALS.Smaller]], content: ['The daily country is not Argentina.', 'It is east of Argentina.', 'It is smaller than Argentina.'] },
    { src: [EVAL_SYMBOLS_COMPONENT[EVALS.Continent], DIRECTION_SYMBOLS[DIRECTIONS.S], AREA_EVAL_SYMBOLS[AREA_EVALS.Larger]], content: ['The daily country is on the same continent as Zambia.', 'It is south of Zambia.', 'It is larger than Zambia.'] },
    { src: [EVAL_SYMBOLS_COMPONENT[EVALS.Border], DIRECTION_SYMBOLS[DIRECTIONS.SE], AREA_EVAL_SYMBOLS[AREA_EVALS.Larger]], content: ['The daily country borders Namibia.', 'It is south-east of Namibia.', 'It is larger than Namibia.'] },
    { src: [EVAL_SYMBOLS_COMPONENT[EVALS.Correct]], content: ['Correct! The daily country is South Africa. You win!'] },
];

const localTimeAtMidnightUTC = DateTime.fromObject({hour: 0, minute: 0}, {zone: 'UTC'}).toLocal().toFormat('HH:mm');

function HelpModal(props) {
    const [activeCarouselItem, setActiveCarouselItem] = useState(0);

    const carouselItemEvalRef = useRef(null);

    function handleCarouselItemChange(item) {
        let newActiveCarouselItem = item % 4;

        if (newActiveCarouselItem < 0)
            newActiveCarouselItem  = 3;

        setActiveCarouselItem(newActiveCarouselItem);
    }

    return (
        <Modal 
            showModal={props.showModal}
            modalOverlayClass="modal-overlay"
            modalClass="modal"
            modalHeaderClass="modal-header"
            modalTitleClass="modal-title"
            modalContentClass="modal-content"
            title="HOW TO PLAY" 
            setShowModal={props.setShowHelp}
            multiClose={false}
        >
            <section>
                <p><span className="app-title-small"><span className="app-title-highlight">Anywhere</span>(dle)</span> in the world!</p>
                <p><span className="app-title-small"><span className="app-title-highlight">Anywhere</span>dle</span> is the all-in-one game to put your knowledge of the world's countries and their national symbols to the test.</p>
                <p>Try to guess the daily country or overseas territory in 6 or less attempts!</p>
            </section>
            <section>
                <div className="modal-separator"></div>
                <p>Each guess you enter may be evaluated in one of the following ways (cycle with arrows):</p>
                <section>
                    <div className="carousel-container">
                        { carouselItems.map((guess, index) => {
                            return (index === activeCarouselItem ?
                                <div key={index} className="carousel-item">
                                    <Guess 
                                        eval={guess.eval} 
                                        dir={guess.dir} 
                                        areaEval={guess.areaEval} 
                                        countryName={guess.countryName}
                                        animate={true}
                                        descriptiveIcons={props.descriptiveIcons} />
                                    {/* {carouselItems[activeCarouselItem].text} */}
                                    <CSSTransition
                                        nodeRef={carouselItemEvalRef}
                                        in={index === activeCarouselItem}
                                        timeout={1000}
                                        classNames="carousel-item-eval-transition"
                                        appear
                                        unmountOnExit
                                    >  
                                    <div ref={carouselItemEvalRef} className="carousel-item-eval-container">
                                        { carouselEvalItems[activeCarouselItem].content.map((item, index) => {
                                            return (
                                                <div key={index} className="carousel-item-eval">
                                                    { 
                                                        cloneElement(
                                                            carouselEvalItems[activeCarouselItem].src[index],
                                                            {className: "carousel-item-icon"}
                                                        )
                                                    }
                                                    {item}
                                                </div>
                                            );
                                        }) }
                                    </div>
                                    </CSSTransition>
                                </div> : null); 
                        }) }
                    </div>
                    <div className="carousel-markers-container">
                        <button className="carousel-prev" onClick={() => handleCarouselItemChange(activeCarouselItem - 1)}>
                            <CarouselPrev role="img" title="Previous guess evaluation type" />
                        </button>
                        { [ ...Array(4).keys() ].map(marker => {
                            return (<button
                                        key={marker}
                                        className="carousel-marker" 
                                        onClick={() => handleCarouselItemChange(marker)}
                                    >
                                        { activeCarouselItem === marker ? <ActiveCarouselMarker role="img" title="Selected guess evaluation type" /> : <InactiveCarouselMarker role="img" title="Unselected guess evaluation type" /> }
                                    </button>);
                        }) }
                        <button className="carousel-next" onClick={() => handleCarouselItemChange(activeCarouselItem + 1)}>
                            <CarouselNext role="img" title="Next guess evaluation type" />
                        </button>
                    </div>
                </section>
                <div className="modal-separator"></div>
                <section>
                    <div className="help-modal-hints-container">
                        <div className="help-modal-hint">
                            <BoundaryIcon className="help-modal-hint-icon" />
                            <p>After each guess is made a portion of the daily country's <b>national boundary</b> is revealed.</p>
                        </div>
                        <div className="help-modal-hint">
                            <COAIcon className="help-modal-hint-icon" />
                            <p>The daily country's <b>national emblem</b> is displayed after <b>3</b> incorrect guesses.</p>
                        </div>
                        <div className="help-modal-hint">
                            <FlagIcon className="help-modal-hint-icon" />
                            <p>The daily country's <b>national flag</b> is displayed after <b>5</b> incorrect guesses.</p>
                        </div>
                    </div>
                    <p><b>Tip</b>: Click the emblem or flag once they have appeared to view a larger version.</p>
                </section>
                <div className="modal-separator"></div>
                <p>A new country is randomly chosen at <b>00:00 UTC</b> ({localTimeAtMidnightUTC} local time) every day!</p>
                <p>Inspired by <a href="https://www.nytimes.com/games/wordle/index.html" target="_blank" rel="noopener noreferrer">Wordle</a> and <a href="https://worldle.teuteuf.fr/" target="_blank" rel="noopener noreferrer">Worldle</a>!</p>
            </section>
        </Modal>
    );
}

export default HelpModal;