import { useEffect, useRef } from "react";

function CopyButton(props) {
    const notifRef = useRef(null);
    const timer = useRef(null);
    
    useEffect(() => {
        return () => clearTimeout(timer?.current);
    }, []);

    function handleClick(e) {
        e.stopPropagation();
        
        if (!notifRef?.current.classList.contains("copied-transition")) {
            notifRef?.current.classList.add("copied-transition");

            navigator.clipboard.writeText(props.content())
                .then(() => {
                    notifRef?.current.setAttribute("aria-hidden", "false");
                    
                    timer.current = setTimeout(() => {
                        notifRef?.current.classList.remove("copied-transition");
                        notifRef?.current.setAttribute("aria-hidden", "true");
                    }, 1000);
                })
                .catch(err => console.log(err));
        }
    }   

    return (
        <button className={props.className} onClick={handleClick} style={{ position: 'relative' }}>
            {props.children}
            <div ref={notifRef} className="copy-button-notification" role="tooltip" aria-hidden="true">{props.notification}</div>
        </button>
    );
}

export default CopyButton;