import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

function getSavedValue(key, dateString) {
    let savedValue = localStorage.getItem(key);

    const presentDate = DateTime.fromFormat(dateString, 'dd/MM/yyyy');
    const previousDayDateString = presentDate.minus({ days: 1 }).toFormat('dd/MM/yyyy');

    if (!savedValue) {
        savedValue = { [previousDayDateString]: false, [dateString]: false };
    } else {
        savedValue = JSON.parse(savedValue);
        const [beforeLatestDayPlayed, latestDayPlayed] = Object.keys(savedValue);

        if (dateString === beforeLatestDayPlayed) {
            savedValue = { [previousDayDateString]: savedValue[beforeLatestDayPlayed], [dateString]: savedValue[beforeLatestDayPlayed] };
        } else if (dateString !== latestDayPlayed) {
            const beforeLatestDayPlayedCopy = savedValue[beforeLatestDayPlayed];
            delete savedValue[beforeLatestDayPlayed];
            
            if (latestDayPlayed === previousDayDateString) {
                savedValue = { ...savedValue, [dateString]: savedValue[latestDayPlayed] };
            } else {
                const latestDayPlayedDate = DateTime.fromFormat(latestDayPlayed, 'dd/MM/yyyy');

                const value = presentDate > latestDayPlayedDate ? savedValue[latestDayPlayed] : beforeLatestDayPlayedCopy;
                savedValue = { [previousDayDateString]: value, [dateString]: value };
            }
        }
    }
    
    return savedValue;
}

function usePersistentDateState(key, dateString) {
    const [value, setValue] = useState(getSavedValue(key, dateString));

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
}

export default usePersistentDateState;