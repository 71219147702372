import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ACHIEVEMENT_ICONS, ACHIEVEMENT_NAMES } from '../util/achievements';

function AchievementNotification(props) {
    const notificationRef = useRef(null);

    function handleClick() {
        props.setShowAchievementNotification(false);
        props.setShowAchievements(true);
    }

    return (
        <CSSTransition
            nodeRef={notificationRef}
            in={props.showNotification}
            timeout={500}
            classNames="notification-transition"
            appear
            unmountOnExit
        >
            <button ref={notificationRef} className="achievement-notification" onClick={handleClick}>
                <div className="achievement-notification-title">{props.achievementNotificationList.length > 1 ? 'Achievements Unlocked' : 'Achievement Unlocked'}</div>
                <div className="achievement-notification-icon-container">
                    { props.achievementNotificationList.sort().map(({ id }) => {
                        return (
                            // <CSSTransition
                            //     key={id}
                            //     in={true}
                            //     timeout={500}
                            //     classNames="notification-transition"
                            //     appear
                            //     unmountOnExit
                            // >
                                <img key={id} src={ACHIEVEMENT_ICONS[id]} alt={ACHIEVEMENT_NAMES[id]} />
                            // </CSSTransition>
                        );
                    }) }
                </div>
            </button>
        </CSSTransition>
    );
}

export default AchievementNotification;