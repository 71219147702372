function Slider(props) {
    return (
        <label className="slider-box">
            <input 
                className="input-checkbox" 
                type="checkbox"
                checked={props.checked || false}
                onChange={() => props.onChange(!props.checked)}
                aria-label={props.ariaLabel} />
            <span className="slider-switch"></span>
        </label>
    );
}

export default Slider;