import { useCallback, useEffect, useState } from 'react';
import confetti from 'canvas-confetti';

import useTargetCountry from './hooks/useTargetCountry';
import useGuesses from './hooks/useGuesses';

import { 
    PageHeader,
    PageColumnContainer,
    HintsContainer,
    UserInputContainer } from './containers';
import ResultModal from './modals/ResultModal';
import { EVALS, AREA_EVALS, getGuessEval, getGuessDirection } from './util/evals';

function Anywheredle({ updateStats, setShowStats, dateString, ...props }) {
    const [targetCountry, targetCountryInfo] = useTargetCountry(dateString, props.startDateString);
    const [guesses, updateGuesses] = useGuesses(dateString);
    const [showResult, setShowResult] = useState(false);

    const shouldDisableGuessing = useCallback(() => {
        return guesses[dateString].indexOf(null) === -1 || 
               guesses[dateString].some(guess => guess?.eval === EVALS.Correct);
    }, [guesses, dateString]);
    
    useEffect(() => {
        let resultTimer, statsTimer;

        if (shouldDisableGuessing()) {
            targetCountry && updateStats(guesses, dateString, targetCountry);
            
            resultTimer = setTimeout(() => {
                setShowResult(true);
            }, 4000);
            
            statsTimer = setTimeout(() => {
                setShowStats(true);
            }, 5000);
        }
        
        return () => {
            clearTimeout(resultTimer);
            clearTimeout(statsTimer);
        }
    }, [shouldDisableGuessing, updateStats, setShowStats, guesses, dateString, targetCountry]);

    function handleBoundaryClick() {
        if (shouldDisableGuessing() && !showResult) {
            setShowResult(true);
        } else {
            setShowResult(false);
        }
    }
    
    function handleNewGuess(country) {
        if (shouldDisableGuessing()) return;

        const newGuesses = [...guesses[dateString]];
        const newGuessPos = newGuesses.indexOf(null);

        const guessEval = getGuessEval(country, targetCountry);
        const guessDirection = getGuessDirection(country, targetCountry);
        const guessAreaEval = country.area < targetCountry.area ? AREA_EVALS.Larger : AREA_EVALS.Smaller;

        const newGuess = {
            name: country.name, 
            eval: guessEval, 
            dir: guessDirection,
            areaEval: guessAreaEval
        };
        
        newGuesses[newGuessPos] = newGuess;
        updateGuesses(newGuesses);

        if (guessEval === EVALS.Correct)
            setTimeout(() => {
                confetti({ disableForReducedMotion: true });
            }, 1650);
    }
    
    return (
        <>
        <ResultModal showModal={showResult} targetCountryInfo={targetCountryInfo} onCloseModal={() => setShowResult(false)} />
        <PageColumnContainer ariaHidden={props.ariaHidden}>
            <PageHeader
                setShowHelp={props.setShowHelp}
                setShowStats={setShowStats}
                setShowAchievements={props.setShowAchievements}
                setShowSettings={props.setShowSettings}
                ariaHidden={props.ariaHidden} />
            { targetCountry &&
                <HintsContainer
                    darkTheme={props.darkTheme}
                    targetCountry={targetCountry} 
                    cellsRevealed={guesses[dateString].map(guess => (guess || shouldDisableGuessing()) ?? false)}
                    boundaryClicked={handleBoundaryClick}
                    revealCOA={(guesses[dateString].indexOf(null) > 2 && !props.challengeMode) || shouldDisableGuessing()} 
                    revealFlag={(guesses[dateString].indexOf(null) > 4 && !props.challengeMode) || shouldDisableGuessing()}
                    gameEnd={shouldDisableGuessing()}
                    challengeMode={props.challengeMode}
                    addAchievementFromEvent={props.addAchievementFromEvent} /> }
            <UserInputContainer
                targetCountryIso={targetCountry?.iso}
                guesses={guesses[dateString]} 
                descriptiveIcons={props.descriptiveIcons}
                handleNewGuess={handleNewGuess} 
                shouldDisableGuessing={shouldDisableGuessing()}
                addAchievementFromEvent={props.addAchievementFromEvent} />
        </PageColumnContainer>
        </>
    );
}

export default Anywheredle;