import { useEffect, useState } from 'react';
import seedrandom from 'seedrandom';
import { DateTime } from 'luxon';

import COUNTRY_DATA from '../util/countries';

function getTargetCountry(dateString, startDateString) {
    let currDate = DateTime.fromFormat(startDateString, 'dd/MM/yyyy');
    const presentDate = DateTime.fromFormat(dateString, 'dd/MM/yyyy');

    let prng, currIso, country;
    let isos = Object.keys(COUNTRY_DATA);
    const countryCoolDown = 60;
    const countriesOnCoolDown = [];

    while (currDate <= presentDate) {
        prng = seedrandom(currDate.toFormat('dd/MM/yyyy'));

        currIso = isos[Math.floor(prng() * isos.length)];
        isos = isos.filter(iso => iso !== currIso);

        country = COUNTRY_DATA[currIso];

        countriesOnCoolDown.push(currIso);
        
        if (countriesOnCoolDown.length > countryCoolDown)
            isos.push(countriesOnCoolDown.shift());
    
        currDate = currDate.plus({ days: 1 });
    }

    return country;
}

function useTargetCountry(dateString, startDateString) {
    const [targetCountry, setTargetCountry] = useState(null);
    const [targetCountryInfo, setTargetCountryInfo] = useState(null);

    useEffect(() => {
        async function getCountryInfo() {
            const name = targetCountry.name;
            const continent = targetCountry.continent;
            let population, area, currency, language, capitalCity, googleMaps, borders;

            if (targetCountry.iso === 'gb-eng' || 
                targetCountry.iso === 'gb-sct' || 
                targetCountry.iso === 'gb-wls' || 
                targetCountry.iso === 'gb-nir') {
                ({ population, area, currency, language, capitalCity, googleMaps, borders } = targetCountry);
            } else {
                try {
                    const response = await fetch(`https://restcountries.com/v3.1/alpha/${targetCountry.iso}`);
                    const data = await response.json();

                    if (data[0]) {
                        ({ population, area, borders } = data[0]);
                        let { currencies, capital, languages, maps } = data[0];
                        
                        currency = currencies && Object.keys(currencies).map(currency => currencies[currency].name).join(', ');
                        language = languages && Object.keys(languages).map(language => languages[language]).join(', ');
                        capitalCity = capital && capital.join(', ');
                        ({ googleMaps } = maps);
                    } 
                } catch(err) {
                    console.error(err);
                }
            }

            population = population.toLocaleString('en');
            area = `${area.toLocaleString('en')} km`;

            return { name, continent, population, area, currency, capitalCity, googleMaps, borders, language };
        }

        if (targetCountry)
            getCountryInfo().then(info => setTargetCountryInfo(info));
    }, [targetCountry]);

    useEffect(() => {
        setTargetCountry(getTargetCountry(dateString, startDateString));
    }, [dateString, startDateString]);

    return [targetCountry, targetCountryInfo];
}

export default useTargetCountry;